import React, { useState, useEffect } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { alertActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBarAndFooter, Slider, Earth } from 'app/components';
import { motion } from "framer-motion";

import { decodeHtmlContent } from "utils/AppHelper";


const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}


const Dashboard = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const team_member_list = [
        {
            id: 1,
            name: 'Thomas Neal',
            position: 'CEO & Co-Founder',
            description: 'Thomas is a visionary leader with extensive experience in the technology industry. He is passionate about leveraging NFT technology to revolutionize enterprise experiences and drive innovation. With a strong background in business development, Thomas spearheads the strategic direction of the company, ensuring its growth and success.',
            img: '/public_assets/images/home/team-section/team-thomas-neal.jpg'
        },
        {
            id: 2,
            name: 'Eugene Dawson',
            position: 'CFO & Co-Founder',
            description: 'Eugene brings a wealth of financial expertise to our team as our Chief Financial Officer. With years of experience in financial management and strategic planning, he ensures the financial health and stability of our company. Eugene’s meticulous attention to detail and analytical skills drive our financial strategies, enabling us to make sound business decisions and achieve sustainable growth.',
            img: '/public_assets/images/home/team-section/team-eugene-dawson.jpg'
        },
        {
            id: 3,
            name: 'Kyle Nelson',
            position: 'CTO',
            description: 'As our Chief Technology Officer and Co-founder, Kyle is the driving force behind our technological advancements. With a deep understanding of virtual building platforms and NFT technologies, he leads our development team in creating innovative solutions. Kyle\'s visionary mindset and technical expertise shape the direction of our company, ensuring we stay at the forefront of the industry.',
            img: '/public_assets/images/home/team-section/team-kyle-nelson.jpg'
        },
        {
            id: 4,
            name: 'Johnnie Lewis',
            position: 'Project Manager (Blockchain)',
            description: 'Johnnie brings extensive experience in project management and a deep understanding of blockchain technology to our team. As the Project Manager (Blockchain), he oversees the successful execution of our projects, ensuring timelines, deliverables, and budgets are met. Johnnie\'s meticulous planning, strong leadership, and expertise in blockchain ensure seamless integration of this technology into our virtual building platform. His commitment to excellence and effective communication drive the successful implementation of our blockchain initiatives.',
            img: '/public_assets/images/home/team-section/team-johnnie-lewis.jpg'
        },
        {
            id: 5,
            name: 'Tina Riley',
            position: 'Marketing Manager',
            description: 'Tina is a dynamic marketer with a passion for promoting innovative technologies. She leads the marketing efforts, implementing effective strategies to create awareness and drive adoption of the NFT virtual building platform. Tina\'s creativity and strategic thinking help position the company as a leader in the virtual building industry.',
            img: '/public_assets/images/home/team-section/team-tina-riley.jpg'
        },
    ]
    const [memberCard, setMemberCard] = useState(team_member_list[0]);

    const partner_list = [
        '/public_assets/images/home/partners-section/bnbchain.png',
        '/public_assets/images/home/partners-section/coinstore.png',
        '/public_assets/images/home/partners-section/metamask.png',
        '/public_assets/images/home/partners-section/websea.png',
        '/public_assets/images/home/partners-section/opensea.png'
    ]

    function renderPartnerItem(list) {
        if (!list || list.length === 0) return null;

        let partners = [];
        list.forEach((item, index) => {
            partners.push(
                <div className="col-6 col-md-4 mb-4" key={'partner_item_' + index}>
                    <div className="partner-item">
                        <img className="img-fluid w-100" src={item}></img>
                    </div>
                </div>
            )
        })

        return (
            <div className="row justify-content-center">
                {partners}
            </div>
        )
    }

    function renderTeamMemberList(list) {
        if (!list || list.length === 0) return;

        let team_members = [];
        list.map((item, index) => {
            team_members.push(
                <div className={`team-member ${ memberCard.id && memberCard.id === item.id ? 'active' : '' }`} key={'team_member_' + index} onClick={() => { setMemberCard(item) }}>
                    <div className="team-member-img" style={{ backgroundImage: 'url("' + item.img + '")' }}></div>
                    <div className="team-member-detail">
                        <h5>{item.name}</h5>
                        <p>{item.position}</p>
                    </div>
                </div>
            )
            return item;
        });

        return team_members;
    }

    function renderTeamMemberCard(item) {
        if (!item) return;

        return (
            <div className="team-member-card-wrapper">
                <div className="team-member-card">
                    <div className="team-member-card-img" style={{ backgroundImage: 'url("' + item.img + '")' }}></div>
                    <div className="team-member-card-detail">
                        <h5>{item.name}</h5>
                        <p className="mb-3">{item.position}</p>
                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <ScreenWithNavBarAndFooter className="page-home">
            <section className="page-section home-header">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/home-header/home-header-bg.jpg)' }}
                ></motion.div>
                <div className="section-bg upper-mask" style={{ backgroundImage: 'url(/public_assets/images/home/home-header/blurry-galaxy.svg)' }}></div>
                <div className="section-bg lower-mask"></div>
                <div className="container-xl">
                    <div className="sec-content text-center">
                        <div className="sec-header">
                            <motion.p
                                initial={{ opacity: 0, scale: 2 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                className="sec-description"
                            >
                                Welcome to
                            </motion.p>
                            <motion.h2
                                initial={{ opacity: 0, scale: 3 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ ease: "easeOut", duration: .75 }}
                                viewport={{ once: true }}
                                className="sec-title"
                            >LocaGo</motion.h2>
                        </div>
                    </div>
                </div>
                <div className="header-earth">
                    <Earth />
                </div>
            </section>
            <section id="video" className="page-section video-section">
                <div className="section-bg bottom-left" style={{ backgroundImage: 'url(/public_assets/images/home/video-section/bottom-left.svg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Application of AI in Every Activity</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Personalized suggestions and real-time multilingual communication via chatbots
                        </motion.p>
                    </div>
                    <motion.div
                        initial={{ y: 200, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-content"
                    >
                        <img src="/public_assets/images/home/video-section/video-top-frame.png" alt="" className="img-fluid w-100"></img>
                        <div className="video-frame">
                            <div className="ratio ratio-16x9">
                                <iframe src="https://www.youtube.com/embed/pcJo5VsV_bE?si=9c0nlhcVD3Yk6t-J" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </section>
            <section id="mission" className="page-section mission-section">
                <div className="section-bg vr-man" style={{ backgroundImage: 'url(/public_assets/images/home/mission-section/locago-mission.png)' }}></div>
                <div className="section-bg middle-right" style={{ backgroundImage: 'url(/public_assets/images/home/mission-section/middle-right.svg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Bringing Online to Offline</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Utilizing VR and AR to bring online experiences into physical spaces
                        </motion.p>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-between">
                            <div className="col-6 col-md-4">
                                <motion.div
                                    initial={{ x: -50, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="mission-group"
                                >
                                    <h3>Optimized Shopping Experience</h3>
                                    <p>AR applications can allow users to try out products online in their real-world spaces, helping them make smarter purchasing decisions. This can also reduce return rates and increase sales for businesses.</p>
                                </motion.div>
                            </div>
                            <div className="col-6 col-md-4">
                                <motion.div
                                    initial={{ x: 50, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                                    viewport={{ once: true }}
                                    className="mission-group mt-md-5"
                                >
                                    <h3>Enhanced Interaction and Engagement</h3>
                                    <p>VR and AR can create highly interactive and immersive experiences compared to traditional media. In this way, they can enhance user engagement and interaction, creating more captivating experiences.</p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="mission2" className="page-section mission2-section pb-0">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/mission2-section/mission2-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="row justify-content-between">
                        <div className="col-md-5">
                            <div className="sec-header mt-5">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Simplifying Technology</motion.h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description"
                                >
                                    User-friendly interface recorded on Blockchain for fairness and equality
                                </motion.p>
                                <motion.a
                                    initial={{ y: 200, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    href="https://download.locago.tech" target="_blank" className="btn btn-primary btn-200 mt-5"
                                >Try it now</motion.a>
                            </div>
                        </div>
                        <div className="col-md-6 px-md-5">
                            <div className="sec-content mt-5 mt-md-0">
                                <motion.img
                                    initial={{ x: 200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}                                  
                                    src="/public_assets/images/home/mission2-section/locago-app.png" alt="" className="img-fluid w-100"></motion.img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="building" className="page-section building-section pb-0">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Location-based Content Building</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Offering location-based content, transforming users' phone screens into AR browsers
                        </motion.p>
                    </div>
                </div>
                <div className="sec-content">
                    <motion.div
                        initial={{ y: 200, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="building-maps"
                    >
                        <div className="building-map-pin pin-1 desktop">
                            <img src="/public_assets/images/home/building-section/building-message.png" alt="" className="img-fluid w-100 building-message"></img>
                            <img src="/public_assets/images/home/building-section/building-pin.png" alt="" className="building-pin"></img>
                        </div>
                        <div className="building-map-pin pin-2">
                            <img src="/public_assets/images/home/building-section/building-message.png" alt="" className="img-fluid w-100 building-message"></img>
                            <img src="/public_assets/images/home/building-section/building-pin.png" alt="" className="building-pin"></img>
                        </div>
                        <div className="building-map-pin pin-3 desktop">
                            <img src="/public_assets/images/home/building-section/building-message.png" alt="" className="img-fluid w-100 building-message"></img>
                            <img src="/public_assets/images/home/building-section/building-pin.png" alt="" className="building-pin"></img>
                        </div>
                        <img src="/public_assets/images/home/building-section/building-maps.png" alt="" className="img-fluid w-100"></img>
                    </motion.div>
                </div>
            </section>
            <section id="rewards" className="page-section rewards-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/rewards-bg.jpg)' }}></div>
                <div className="section-bg top-right" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/top-right.svg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Instant Rewards for Interaction</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Immediate rewards from businesses akin to discovering treasures, fostering excitement
                        </motion.p>
                        <motion.a
                            initial={{ y: 200, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            href="https://download.locago.tech" target="_blank" className="btn btn-primary btn-200 mt-5"
                        >Try it now</motion.a>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-center reward-grid">
                            <div className="col-md-2">
                                <motion.div
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="reward-item"
                                >
                                    <div className="reward-item-img" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/slide-1.jpg)' }}></div>
                                </motion.div>
                            </div>
                            <div className="col-md-2">
                                <div className="reward-item-stack">
                                    <motion.div
                                        initial={{ y: 200, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        viewport={{ once: true }}
                                        className="reward-item"
                                    >
                                        <div className="reward-item-img" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/slide-2.jpg)' }}></div>
                                    </motion.div>
                                    <motion.div
                                        initial={{ y: 200, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5, delay: .25 }}
                                        viewport={{ once: true }}
                                        className="reward-item"
                                    >
                                        <div className="reward-item-img" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/slide-3.jpg)' }}></div>
                                    </motion.div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <motion.div
                                    initial={{ y: -200, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="reward-item"
                                >
                                    <div className="reward-item-img" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/slide-4.jpg)' }}></div>
                                </motion.div>
                            </div>
                            <div className="col-md-2">
                                <div className="reward-item-stack">
                                    <motion.div
                                        initial={{ y: 200, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        viewport={{ once: true }}
                                        className="reward-item"
                                    >
                                        <div className="reward-item-img" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/slide-5.jpg)' }}></div>
                                    </motion.div>
                                    <motion.div
                                        initial={{ y: 200, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5, delay: .25 }}
                                        viewport={{ once: true }}
                                        className="reward-item"
                                    >
                                        <div className="reward-item-img" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/slide-6.jpg)' }}></div>
                                    </motion.div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <motion.div
                                    initial={{ x: 200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="reward-item"
                                >
                                    <div className="reward-item-img" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/slide-7.jpg)' }}></div>
                                </motion.div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="col-md-3">
                                <img src="/public_assets/images/home/rewards-section/slide-dots.png" alt="" className="img-fluid w-100"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="rewards2" className="page-section rewards2-section">
                <div className="section-bg wave" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/wave.png)' }}></div>
                <div className="section-bg blur-light" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/blur-light.svg)' }}></div>
                <div className="container-xl">
                    <motion.div
                        initial={{ y: -50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="rewards-image desktop"
                    >
                        <img src="/public_assets/images/home/rewards-section/locago-miniapp.png" alt="" className="img-fluid w-100"></img>
                    </motion.div>
                    <div className="rewards-wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="sec-header">
                                    <motion.h2
                                        initial={{ x: -200, opacity: 0 }}
                                        whileInView={{ x: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        viewport={{ once: true }}
                                        className="sec-title"
                                    >LocaGo <span className="fw-normal">X</span> <i className="fa-brands fa-telegram"></i> Telegram</motion.h2>
                                    <motion.p
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        viewport={{ once: true }}
                                        className="sec-description"
                                    >
                                        Dive into the thrilling world of LocaGo, the ultimate tap-to-earn game where fortunes are just a tap away. Join now and start mining coins from your unique NFT Location. With a massive prize pool of up to $278,399,200, the next big winner could be you!
                                    </motion.p>
                                    <motion.a
                                        initial={{ y: 50, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        viewport={{ once: true }}
                                        href="https://t.me/locagoapp_bot/" target="_blank" className="btn btn-primary btn-200 mt-5"
                                    >Try it now</motion.a>
                                </div>
                            </div>
                            <div className="col-md-6 mobile">
                                <motion.img
                                    initial={{ y: -50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    src="/public_assets/images/home/rewards-section/locago-miniapp.png" alt="" className="img-fluid w-100 mt-4"></motion.img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="bussiness_support" className="page-section bussiness-support-section pt-md-0">
                <div className="section-bg blur-light desktop" style={{ backgroundImage: 'url(/public_assets/images/home/bussiness-support-section/blur-light.svg)' }}></div>
                <div className="container-xl">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Support for Brick-and-Mortar Businesses</motion.h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description"
                                >
                                    Boosting revenue and community connections for physical stores, turning marketing expenses into future investments.
                                </motion.p>
                                <motion.a
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    href="#" className="btn btn-primary btn-200 mt-5"
                                >Contact Us</motion.a>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="sec-container">
                                <motion.img
                                    initial={{ x: 200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    src="/public_assets/images/home/bussiness-support-section/locago-business-support.png" alt="" className="img-fluid w-100 mt-4 mt-md-0"></motion.img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section id="about" className="page-section about-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/about-section/ellipse.svg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <div className="btn btn-outline-light rounded-pill sec-badge">About Us</div>
                        <h2 className="sec-title">LCM Technology PTE. LTD.</h2>
                        <p className="sec-description">
                            LocaGo is a location-based social network developed by LCM Technology PTE. LTD, headquartered in Singapore. Our mission is to seamlessly integrate Blockchain Technology into marketing, revolutionizing business engagement. We make cryptocurrency accessible in daily life through dynamic marketing solutions.
                        </p>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-3 mb-3 mb-md-0">
                                <div className="about-item">
                                    <img src="/public_assets/images/home/about-section/icon-microphone.png" alt="" width={52} height={52}></img>
                                    <h3>Integrating AI in all activities</h3>
                                    <p>AI assists in search, location recommendations, community feedback, and smart interactions via chatbots and interfaces.</p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-3 mb-md-0">
                                <div className="about-item">
                                    <img src="/public_assets/images/home/about-section/icon-check.png" alt="" width={52} height={52}></img>
                                    <h3>Bringing Online to Offline</h3>
                                    <p>Unleashing exciting opportunities for users to connect and interact in real-life locations, drawing inspiration from the famous game PokeMon Go.</p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-3 mb-md-0">
                                <div className="about-item">
                                    <img src="/public_assets/images/home/about-section/icon-edit.png" alt="" width={52} height={52}></img>
                                    <h3>Creating Location-based Content</h3>
                                    <p>Crafting captivating content tailored to specific locations, enticing users to visit physical locations to explore and interact.</p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-3 mb-md-0">
                                <div className="about-item">
                                    <img src="/public_assets/images/home/about-section/icon-home.png" alt="" width={52} height={52}></img>
                                    <h3>Supporting Physical Businesses</h3>
                                    <p>Empowering countless physical stores to attract customers and boost profitability.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="explore" className="page-section explore-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/explore-section/explore-bg.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <div className="btn btn-outline-light rounded-pill sec-badge">Explore</div>
                        <p className="sec-description">Easily spot dining, shopping, and entertainment options with intuitive icons. Explore top-rated locations, access detailed information and captivating imagery, and discover user ratings and reviews for better choices.</p>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-1 mobile"></div>
                            <div className="col-11 col-md-4">
                                <h3 className="mb-4 mb-md-5">
                                    <img className="me-4" src="/public_assets/images/home/explore-section/icon-maps.png" width={80} height={80}></img>
                                    <span>Location Map</span>
                                </h3>
                            </div>
                            <div className="col-md-8 desktop"></div>
                            <div className="col-4 col-md-7"></div>
                            <div className="col-8 col-md-5 mt-md-5 position-relative">
                                <h3 className="mb-4 mb-md-5">
                                    <img className="me-4" src="/public_assets/images/home/explore-section/icon-location-marker.png" width={80} height={80}></img>
                                    <span>Hotspots</span>
                                </h3>
                                <div className="hotspot-features mt-3 mb-4 mb-md-3 desktop">
                                    <div className="hotspot-feature-item">
                                        <img src="/public_assets/images/home/explore-section/icon-check.png" alt="" width={32} height={32}></img>
                                        <span>Embark on an adventure with our dynamic map, showcasing beloved hotspots and nearby activities</span>
                                    </div>
                                    <div className="hotspot-feature-item">
                                        <img src="/public_assets/images/home/explore-section/icon-check.png" alt="" width={32} height={32}></img>
                                        <span>Easily spot dining, shopping, and entertainment options with intuitive icons</span>
                                    </div>
                                    <div className="hotspot-feature-item">
                                        <img src="/public_assets/images/home/explore-section/icon-check.png" alt="" width={32} height={32}></img>
                                        <span>Interact with community ratings and reviews to discover top destinations</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-6">
                                <h3 className="mb-4 mb-md-5">
                                    <img className="me-4" src="/public_assets/images/home/explore-section/icon-bell.png" width={80} height={80}></img>
                                    <span>Nearby Activities</span>
                                </h3>
                            </div>
                            <div className="col-12">
                                <div className="hotspot-features mobile">
                                    <div className="hotspot-feature-item">
                                        <img src="/public_assets/images/home/explore-section/icon-check.png" alt="" width={32} height={32}></img>
                                        <span>Embark on an adventure with our dynamic map, showcasing beloved hotspots and nearby activities</span>
                                    </div>
                                    <div className="hotspot-feature-item">
                                        <img src="/public_assets/images/home/explore-section/icon-check.png" alt="" width={32} height={32}></img>
                                        <span>Easily spot dining, shopping, and entertainment options with intuitive icons</span>
                                    </div>
                                    <div className="hotspot-feature-item">
                                        <img src="/public_assets/images/home/explore-section/icon-check.png" alt="" width={32} height={32}></img>
                                        <span>Interact with community ratings and reviews to discover top destinations</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="ecosystem" className="page-section ecosystem-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <div className="btn btn-outline-light rounded-pill sec-badge">Ecosystem</div>
                        <p className="sec-description">LocaGo not only facilitates connections with the world around users but also fosters opportunities to contribute value and explore physical locations.</p>
                        <div className="text-center">
                            <img className="img-fluid mt-4" src="/public_assets/images/home/ecosystem-section/header-icons.png" alt="" width={320} height={52}></img>
                        </div>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-4 mb-3 ecosystem-col order-1">
                                <div className="ecosystem-item">
                                    <h3>
                                        <img className="me-2" src="/public_assets/images/home/ecosystem-section/icon-nfc.png" width={48} height={48}></img>
                                        <span>LocaNFC</span>
                                    </h3>
                                    <p>LocaGo creates vibrant Gift Hubs, enticing customers with diverse offerings. Businesses leverage LocaGo to captivate and retain customers with enticing gift opportunities, sponsored by the platform or store owners, enhancing interaction and loyalty.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3 ecosystem-col order-4 order-md-2">
                                <div className="ecosystem-item">
                                    <h3>
                                        <img className="me-2" src="/public_assets/images/home/ecosystem-section/icon-voucher.png" width={48} height={48}></img>
                                        <span>LocaVouchers</span>
                                    </h3>
                                    <p>LocaVouchers are discount coupons used at partnered businesses. Discounts and quantities are set by businesses. With D-LocaVouchers at $1 and C-LocaVouchers at $0.1, users can buy with LCA tokens for discounted offers.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3 ecosystem-col order-3">
                                <div className="ecosystem-item">
                                    <h3>
                                        <img className="me-2" src="/public_assets/images/home/ecosystem-section/icon-gift.png" width={48} height={48}></img>
                                        <span>Gift Warehouse</span>
                                    </h3>
                                    <p>20% of LocaGo tokens fund the LocaNFC Gift Vault. The Vault offers valuable items, LCG tokens, or vouchers to registered LocaNFC businesses. It attracts users to locations for gift hunting post-purchase.</p>
                                </div>
                            </div>
                            <div className="col-md-5 mb-3 ecosystem-col order-2 order-md-4">
                                <div className="ecosystem-item text-center pb-0">
                                    <h3 className="mb-4">
                                        AI-powered social network <br className="desktop" />fostering local connections.
                                    </h3>
                                    <img className="img-fluid w-75 shadow-lg" src="/public_assets/images/home/ecosystem-section/ask-loca.png" alt=""></img>
                                </div>
                            </div>
                            <div className="col-md-7 mb-3 ecosystem-col order-5">
                                <div className="ecosystem-item pt-md-0 pb-0">
                                    <div className="row align-items-center h-100">
                                        <div className="col-md-5">
                                            <h3>
                                                <img className="me-2" src="/public_assets/images/home/ecosystem-section/icon-location-marker.png" width={48} height={48}></img>
                                                <span>LocaNFTs</span>
                                            </h3>
                                            <p>LocaNFTs are digital place representations on Blockchain, establishing ownership. They generate LocaBonus via algorithms, forming ecosystem bedrock.</p>
                                        </div>
                                        <div className="col-md-7 position-relative ecosystem-nfts-img">
                                            <div className="section-bg z-0" style={{ backgroundImage: 'url(/public_assets/images/home/ecosystem-section/nfts.png)' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </section>
            <section id="tokenomics" className="page-section tokenomics-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <div className="btn btn-outline-light rounded-pill sec-badge">Tokenomics</div>
                        <h2 className="sec-title">Our Token</h2>
                        <p className="sec-description mb-4">LocaGo&apos;s Tokenomics aims to create a balanced and sustainable economic mechanism, encouraging active participation from the user and business communities while creating value for both LCG and LCB tokens.</p>
                        <div className="text-center">
                            <a href="https://locago.gitbook.io/locago-whitepaper-official" target="_blank" className="btn btn-light btn-200">Whitepaper</a>
                            <a href="https://app.solidproof.io/projects/locago" target="_blank" className="btn btn-primary mt-2 mt-lg-0 ms-lg-3 btn-200">Audit</a>
                        </div>
                    </div>
                    <div className="sec-content">
                        <img className="img-fluid w-100 desktop" src="/public_assets/images/home/tokenomics-section/locago-tokenomics.png"></img>
                        <img className="img-fluid w-100 mobile" src="/public_assets/images/home/tokenomics-section/locago-tokenomics-mobile.png"></img>
                    </div>
                </div>
            </section>
            <section id="roadmap" className="page-section roadmap-section">
                <div className="container-xl">
                    <div className="roadmap-section-wrapper">
                        <div className="sec-header text-center">
                            <div className="btn btn-outline-light rounded-pill sec-badge">Roadmap</div>
                            <h2 className="sec-title">Roadmap 2021 - 2026</h2>
                        </div>
                        <div className="sec-content">
                            <div className="roadmap">
                                <div className="roadmap-line"></div>
                                <div className="roadmap-item">
                                    <p className="roadmap-item-year">2021</p>
                                    <div className="roadmap-item-content">
                                        <h3 className="roadmap-item-title">LocaMos Conception & Ideas</h3>
                                        <ul>
                                            <li>Introduction of VR 360 feature</li>
                                            <li>Introduction of AR feature</li>
                                            <li>Launch of LocaNFT on the digital map</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <p className="roadmap-item-year">2022</p>
                                    <div className="roadmap-item-content">
                                        <h3 className="roadmap-item-title">Development of LocaMos Platform (IOS, Android)</h3>
                                        <ul>
                                            <li>Pilot testing of LocaGift</li>
                                            <li>Development of LocaChain - Testnet</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <p className="roadmap-item-year">2023</p>
                                    <div className="roadmap-item-content">
                                        <h3 className="roadmap-item-title">Diversification & Service Expansion</h3>
                                        <ul>
                                            <li>Development of LocaMeet</li>
                                            <li>Development of LocaBiz</li>
                                            <li>Introduction of LocaBuilding & LocaShop</li>
                                            <li>Launch of LocaAds</li>
                                            <li>Introduction of LocaCall</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <p className="roadmap-item-year">2024</p>
                                    <div className="roadmap-item-content">
                                        <h3 className="roadmap-item-title">Functionality Expansion and Market Transactions</h3>
                                        <ul>
                                            <li>Introduction of LocaItems (February)</li>
                                            <li>Launch of LocaEvent (March)</li>
                                            <li>Presale of tokens (April)</li>
                                            <li>Listing LocaGo on CEX (Centralized Exchange) platforms (June)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <p className="roadmap-item-year">2025</p>
                                    <div className="roadmap-item-content">
                                        <h3 className="roadmap-item-title">Optimization and Brand Shaping</h3>
                                        <ul>
                                            <li>Continuous optimization of features and user experience</li>
                                            <li>Expansion of partnerships and community engagement</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <p className="roadmap-item-year">2026</p>
                                    <div className="roadmap-item-content">
                                        <h3 className="roadmap-item-title">Innovation and DAO Establishment</h3>
                                        <ul>
                                            <li>Continuous updates and improvement of LocaGo application</li>
                                            <li>Introduction of new features</li>
                                            <li>Interaction with the community and partners</li>
                                            <li>Establishment of a decentralized autonomous organization (DAO)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="partners" className="page-section partners-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <div className="btn btn-outline-light rounded-pill sec-badge">Our Partner</div>
                        <h2 className="sec-title">Trusted beyond the Ecosystem</h2>
                        <p className="sec-description">Together, we&apos;re reshaping the landscape of location-based social networking and empowering communities to thrive.</p>
                    </div>
                    <div className="sec-content">
                        { renderPartnerItem(partner_list) }
                    </div>
                </div>
            </section>
            <section id="team" className="page-section team-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <div className="btn btn-outline-light rounded-pill sec-badge">Our Team</div>
                        <h2 className="sec-title">Meet our Team member</h2>
                        <p className="sec-description">Experts driving virtual building innovation with passion and dedication. Collaborative, creative, and customer-focused, we deliver exceptional NFT-powered solutions.</p>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-6 mb-5 mb-md-0">
                                { renderTeamMemberCard(memberCard) }
                            </div>
                            <div className="col-md-6">
                                <div className="team-member-list">
                                    { renderTeamMemberList(team_member_list) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact" className="page-section contact-section">
                <div className="container-xl">
                    <div className="contact-section-wrapper">
                        <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/contact-section/locago-bg.svg)' }}></div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="sec-header">
                                    <div className="btn btn-outline-light rounded-pill sec-badge">Get in touch</div>
                                    <h2 className="sec-title">Let&apos;s chat</h2>
                                    <p className="sec-description">Interested in learning more about LocaGo? We’ll get back to you as soon as possible. </p>
                                </div>
                                <div className="sec-content">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="first_name" className="form-label">Your first name</label>
                                                <input type="text" className="form-control" id="first_name" placeholder="Enter your first name"></input>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="last_name" className="form-label">Your last name</label>
                                                <input type="text" className="form-control" id="last_name" placeholder="Enter your last name"></input>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-4 mb-md-5">
                                                <label htmlFor="email" className="form-label">Your email address</label>
                                                <input type="email" className="form-control" id="email" placeholder="Enter your email address"></input>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100">Sign me up</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </ScreenWithNavBarAndFooter>
    )
})

const NewsSection = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const news = [
        {
            "title": "[Yahoo] LocaMos Global AG Officially Inaugurates New Headquarters in Zug, Switzerland, Bridging MarTech and Blockchain Innovation",
            "description": "LocaMos Global AG, an emerging name in the technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://finance.yahoo.com/news/locamos-global-ag-officially-inaugurates-034100325.html",
            "img_url": "https://s.yimg.com/ny/api/res/1.2/wCXsAxlezV.JLsLmkuKnmQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTY4MQ--/https://media.zenfs.com/en/newsfile_64/adb3aa3e19ddfa3700b678348d18581f",
        },
        {
            "title": "[Business Insider] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://markets.businessinsider.com/news/stocks/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain-1032786035",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
        {
            "title": "[Benzinga] LocaMos Global AG commits to applying technology to address real-life challenges. The company offers a range of tools that help attract and engage customers through high-experiential features to support physical businesses, creating smoother business operations and growth.",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.benzinga.com/pressreleases/23/11/35636290/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain",
            "img_url": "/public_assets/images/news/news-2.jpg",
        },
        {
            "title": "[News Max] LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland",
            "description": "LocaMos Global AG, a technology company based in Zug, Switzerland, recently established, is making waves by combining two crucial facets in the technology industry: MarTech (Marketing Technology) and Blockchain. MarTech emphasizes utilizing technology to optimize and enhance marketing campaigns, while Blockchain has the potential to transform many sectors through its security and transparency in transactions.",
            "url": "https://pr.newsmax.com/article/LocaMos-Global-AG-Pioneering-a-Revolutionary-Blend-of-MarTech-and-Blockchain?storyId=6549e967aabbe7000889a161&fbclid=IwAR2GzfuyxswWFZHM5aLdlhJKoWBRjTR4aCwjGiU1DqcqJf5NNIxUdbXUSOk_aem_AehULbt_gCLI7P0HxUPS64lhOmWMIdh46HdHz3cLs3a-NmaEkM0gYbla8wừycQujJh8",
            "img_url": "https://storage.googleapis.com/mmstudio-images/gallery/AhvOgFGA4jcj9Xtx9NZIpIwF4Vw2/89112505-1699329968-1.jpg",
        },
        {
            "title": "[TGAM] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.theglobeandmail.com/investing/markets/markets-news/MarketersMEDIA%20Newswire/21772309/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain/?fbclid=IwAR2gZL6arEordyYiLCe4KFjHIgOq2Tl6uJJjBauzNabt9qAy2barC64eymg_aem_Aehjv52c61JvXo8gw0HebSzgI5HKXPePgq0BPU-ueX52dFqE_r7yiH5vjJbDtxAdx7g",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
    ]

    return (
        <section id="news" className={`page-section news-section ${props.className !== '' ? props.className : ''}`}>
            <div className="container-xl">
                <div className="sec-header text-center">
                    <h2 className="sec-title">Our Latest News</h2>
                    <a href="#" className="btn btn-lg btn-primary btn-200 px-5">View all posts</a>
                </div>
                <div className="sec-content">
                    <Slider
                        className="news-slider"
                        list={news}
                        renderItem={(item, index) => (
                            <a href={item.url} target="_blank" className="article">
                                <div className="article-image">
                                    <img src={item.img_url} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="article-info">
                                    <h4 className="article-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                                    <p className="article-description" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.description) }}></p>
                                </div>
                            </a>
                        )}
                        overrideConfig={{
                            slidesPerView: 1,
                            breakpoints: {
                                768: {
                                    slidesPerView: 3
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    )
})

export {
    Dashboard,
    NewsSection
}