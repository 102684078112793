import React, { useState, useEffect, useRef } from 'react';
import './Earth.scss';

// ThreeJS
import * as THREE from 'three';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import { 
    EffectComposer,
	EffectPass,
    RenderPass,
    BlendFunction,
	SelectiveBloomEffect,
    SMAAEffect,
    SMAAPreset,
    EdgeDetectionMode,
    PredicationMode,
    TextureEffect
} from "postprocessing";

const Earth = props => {
    const refContainer = useRef(null);

    const [modelLoaded, setModelLoaded] = useState(false);

    const renderer = new THREE.WebGLRenderer( { 
        powerPreference: "high-performance",
        antialias: false,
        stencil: false,
        depth: false,
        alpha: true
    } );

    var viewport_width = window.innerWidth, viewport_height = window.innerHeight;

    renderer.setSize(viewport_width, viewport_height);
    renderer.toneMapping = THREE.ReinhardToneMapping;
    
    const pmremGenerator = new THREE.PMREMGenerator( renderer );

    const scene = new THREE.Scene();
    // scene.background = new THREE.Color( 0x040C16 );

    scene.environment = pmremGenerator.fromScene( new RoomEnvironment( renderer ), 0.04 ).texture;

    const camera = new THREE.PerspectiveCamera( 75, viewport_width / viewport_height, 0.1, 10000 );
    camera.position.y = .9;
    camera.position.z = 5.0;
    scene.rotation.y = -0.5;
    

    // Setup Effects
    const composer = new EffectComposer(renderer);

    const renderPass = new RenderPass(scene, camera);

    const smaaEffect = new SMAAEffect( {
        preset: SMAAPreset.HIGH,
        edgeDetectionMode: EdgeDetectionMode.COLOR,
        predicationMode: PredicationMode.DEPTH
    });

    const edgesTextureEffect = new TextureEffect({
        blendFunction: BlendFunction.SKIP,
        texture: smaaEffect.renderTargetEdges.texture
    });

    const weightsTextureEffect = new TextureEffect({
        blendFunction: BlendFunction.SKIP,
        texture: smaaEffect.renderTargetWeights.texture
    });

    const bloomEffect = new SelectiveBloomEffect(scene, camera, {
        blendFunction: BlendFunction.LIGHTEN
    });

    var effect_pass = [
        smaaEffect,
        edgesTextureEffect,
        weightsTextureEffect,
        bloomEffect
    ]

    const effectPass = new EffectPass(
        camera,
        ...effect_pass
    );
                
    composer.addPass( renderPass );
    composer.addPass( effectPass );

    // GLTF 3D Model Loader
    const gltf_loader = new GLTFLoader();

    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    const dracoLoader = new DRACOLoader();
    // Specify path to a folder containing WASM/JS decoding libraries.
    dracoLoader.setDecoderPath( '/public_assets/js/draco/' );
    dracoLoader.setDecoderConfig( { type: 'js' } );
    // Optional: Pre-fetch Draco WASM/JS module.
    dracoLoader.preload();

    gltf_loader.setDRACOLoader( dracoLoader );

    // Animation mixer
    var mixer = false;

    const animation_clock = new THREE.Clock();

    // Earth 3D Object config
    const icon_model = {
        url: '/public_assets/images/home/home-header/globe.glb',
        model_id: 'earth',
        scale: 1,
        effect_pass: {
            bloom: {
                enabled: true,
                luminancePass: true,
                luminanceThreshold: 0.2,
                luminanceSmoothing: 1,
                intensity: 0.8,
                radius: 0.8
            }
        },
        animation_time_scale: 0.05
    }

    function animate() {
        if (camera.position.z > 1.25) {
            camera.position.z -= 0.01;
        }
    
        if (mixer) {
    
            const delta = animation_clock.getDelta();
        
            mixer.update( delta );
    
        }
            
        composer.render();
    
        requestAnimationFrame( animate );
    
    }

    function onWindowResize() {
        if (
            Math.abs(window.innerWidth - viewport_width) > 100
            || Math.abs(window.innerHeight - viewport_height) > 100
        ) {
            viewport_width = window.innerWidth;
            viewport_height = window.innerHeight;

            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize( window.innerWidth, window.innerHeight );
        }
    }

    useEffect(() => {
        console.log('modelLoaded', modelLoaded);

        window.addEventListener( 'resize', onWindowResize );

        if (refContainer.current && !modelLoaded) {

            refContainer.current.appendChild( renderer.domElement );

            scene.clear();

            // Load glTF resource
            gltf_loader.load(
                // resource URL
                icon_model.url,
                // called when the resource is loaded
                function (gltf) {
                    console.log('gltf_loader', gltf);

                    // update effectPass configs
                    if (
                        icon_model.effect_pass
                        && icon_model.effect_pass.bloom
                        && icon_model.effect_pass.bloom.enabled
                    ) {
                        console.log('has BloomPass');

                        const { luminancePass, luminanceThreshold, luminanceSmoothing, intensity, radius } = icon_model.effect_pass.bloom;

                        bloomEffect.luminancePass.enabled = luminancePass;
                        bloomEffect.luminanceMaterial.threshold = luminanceThreshold;
                        bloomEffect.luminanceMaterial.smoothing = luminanceSmoothing;
                        bloomEffect.intensity = intensity;
                        bloomEffect.mipmapBlurPass.radius = radius;

                    }

                    // traverse model to enable effectPass
                    bloomEffect.selection.clear();
                    gltf.scene.traverse( function ( child ) {
                        child.frustumCulled = false;

                        if ( child.isMesh && child.name.includes('satelite') && !bloomEffect.selection.has(child) ) {
                            bloomEffect.selection.add(child);
                        }

                        if ( child.isMesh && child.name === 'globe' ) {
                            child.material.emissive = new THREE.Color('#FFFFFF');
                            child.material.emissiveIntensity = 0.05;
                            // bloomEffect.selection.add(child);
                        }
                    })

                    if (!modelLoaded) {
                        scene.clear();
                        scene.add(gltf.scene);
                    }

                    if (gltf.animations.length > 0) {
                        mixer = new THREE.AnimationMixer(gltf.scene);
                        gltf.animations.forEach(function(animation, index) {
                            mixer.clipAction(animation).play();
                        })
                        mixer.timeScale = icon_model.animation_time_scale;
                    }

                },
                // called while loading is progressing
                function (xhr) {

                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');

                },
                // called when loading has errors
                function (error) {

                    console.log('An error happened', error);

                }
            );
            
            animate();
            
            console.log('????', bloomEffect.selection);

            setModelLoaded(true);

        }

        return () => {
        }
    }, [modelLoaded])
    
    return (
        <div ref={refContainer} className="earth-container"></div>
    );
}

export default Earth;