import React, { useEffect, useState } from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

import { motion } from "framer-motion";

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            <motion.div
                initial={{ y: -200, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ ease: "easeOut", duration: .5 }}
                className="header-social"
            >
                <div className="header-social-link">
                    <a href="#" target="_blank" className="btn btn-icon p-0">
                        <img src="/public_assets/images/home/home-header/icon-facebook.png" alt="" width={36} height={36}></img>
                    </a>
                    <a href="#" target="_blank" className="btn btn-icon p-0">
                        <img src="/public_assets/images/home/home-header/icon-instagram.png" alt="" width={36} height={36}></img>
                    </a>
                    <a href="#" target="_blank" className="btn btn-icon p-0">
                        <img src="/public_assets/images/home/home-header/icon-linkedin.png" alt="" width={36} height={36}></img>
                    </a>
                </div>
                <img src="/public_assets/images/home/home-header/social-line.png" alt="" width={2} height={73}></img>
                <span>Social Media</span>
            </motion.div>
            <motion.div
                initial={{ y: -200, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ ease: "easeOut", duration: .5, delay: 1 }}
                className="header-scroll-down"
            >
                <span>Scroll Down</span>
                <img src="/public_assets/images/home/home-header/long-arrow-down.png" alt="" width={14} height={93}></img>
            </motion.div>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, user } = props.auth;

    const [navbarScroll, setNavbarScroll] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', handlePageScroll)

        return () => {
            document.removeEventListener('scroll', handlePageScroll)
        }
    }, [])

    function handlePageScroll(e) {
        // console.log('pageScroll', window.scrollY);
        if (window.scrollY > 96) {
            setNavbarScroll(true);
        } else {
            setNavbarScroll(false);
        }

    }

    function showContactForm() {
        window.zE(function() {
            window.zE.activate();
        });
    }

    return (
        <nav className={`navbar navbar-expand-lg navbar-dark fixed-top top-navbar ${navbarScroll ? 'scroll' : ''}`}>
            <div className="container-xl">
                <button className="navbar-toggler ms-3 order-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-1" to={NAVIGATION.DASHBOARD}>
                    <img src="/public_assets/images/header-logo.svg" alt="" height={40}></img>
                </Link>
                <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto pt-3 pt-md-0 navbar-center">
                        <li className="nav-item">
                            <Link to={NAVIGATION.ABOUT_US} className="nav-link">{text.top_nav_bar.about_us[lang]}</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {text.top_nav_bar.features[lang]}
                            </a>
                            <ul className="dropdown-menu">
                                <li><Link to={NAVIGATION.FEATURES} className="dropdown-item">{text.top_nav_bar.features.all_features[lang]}</Link></li>
                                <li><NavLink to={NAVIGATION.FOR_USERS} className="dropdown-item">{text.top_nav_bar.features.for_users[lang]}</NavLink></li>
                                <li><NavLink to={NAVIGATION.FOR_BUSINESSES} className="dropdown-item">{text.top_nav_bar.features.for_businesses[lang]}</NavLink></li>
                                <li><NavLink to={NAVIGATION.FOR_OWNERS} className="dropdown-item">{text.top_nav_bar.features.for_owners[lang]}</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to={NAVIGATION.TOKENOMICS} className="nav-link">{text.top_nav_bar.tokenomics[lang]}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={NAVIGATION.MARKET_DEVELOPER} className="nav-link">{text.top_nav_bar.market_developer[lang]}</Link>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={showContactForm}>{text.top_nav_bar.support[lang]}</a>
                        </li>
                    </ul>
                </div>
                <ul className="navbar-nav ms-3 order-2 order-lg-4">
                    <li className="nav-item">
                        <a href="#" className="btn btn-primary" onClick={showContactForm}>Contact Us</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)((props) => {
	const { pageInfo } = props;
	const lang = pageInfo.display_lang;

    function showContactForm() {
        let email = document.getElementById('footerEmail');
        window.zE(function() {
            window.zE.identify({
                name: ' ',
                email: email.value,
            });
            window.zE.activate();
        });
    }

	return (
		<footer className="site-footer">
            <div className="footer-bg" style={{ backgroundImage: 'url(/public_assets/images/footer-logo.svg)' }}></div>
			<div className="container-xl">
                <div className="row footer-row">
                    <div className="col-md-6">
                        <p className="h1 flex-fill mb-3 mb-md-0">Let's chat</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-column flex-md-row gap-3">
                            <input id="footerEmail" type="email" className="form-control" placeholder="Email address"></input>
                            <button className="btn btn-primary btn-200" onClick={showContactForm}>Send Now</button>
                        </div>
                    </div>
                </div>
				<div className="row footer-row">
                    <div className="col-md-6">
                        <h5>{text.top_nav_bar.contact[lang]}</h5>
                        <ul className="footer-links">
                            <li>
                                <div className="footer-contact-info">
                                    <i className="fa-light fa-fw fa-location-dot"></i>
                                    <span>Address: 18 Sin Ming Lane, #07-13, <br />Midview City, Singapore (573960)</span>
                                </div>
                            </li>
                            <li>
                                <div className="footer-contact-info">
                                    <i className="fa-light fa-fw fa-briefcase"></i>
                                    <span>UEN: 202243318C</span>
                                </div>
                            </li>
                            <li>
                                <a href="mailto:contact@locago.tech" target="_blank" className="footer-contact-info">
                                    <i className="fa-light fa-fw fa-envelope"></i>
                                    <span>Email: contact@locago.tech</span>
                                </a>
                            </li>
                        </ul>
                    </div>
					<div className="col-6 col-md-2">
						<h5>{text.footer.quick_links[lang]}</h5>
						<ul className="footer-links">
							<li>
								<a href="https://locago.gitbook.io/locago-whitepaper-official" target="_blank">{text.footer.resources.white_paper[lang]}</a>
							</li>
							<li>
                                <a href="https://app.solidproof.io/projects/locago" target="_blank">{text.footer.resources.audit[lang]}</a>
							</li>
							<li>
								<Link to={NAVIGATION.FAQS}>{text.footer.resources.faqs[lang]}</Link>
							</li>
						</ul>
					</div>
					<div className="col-6 col-md-2">
						<h5>{text.footer.resources[lang]}</h5>
						<ul className="footer-links">
                            <li>
                                <Link to={NAVIGATION.ABOUT_US} className="nav-link">{text.top_nav_bar.about_us[lang]}</Link>
                            </li>
                            <li>
                                <Link to={NAVIGATION.FEATURES} className="nav-link">{text.top_nav_bar.features.all_features[lang]}</Link>
                            </li>
                            <li>
                                <Link to={NAVIGATION.TOKENOMICS} className="nav-link">{text.top_nav_bar.tokenomics[lang]}</Link>
                            </li>
						</ul>
					</div>
					<div className="col-md-2">
						<h5>{text.footer.follow_us[lang]}</h5>
						<div className="footer-social-links">
                            <a href="https://t.me/locagoofficial" target="_blank" className="btn btn-sm btn-primary w-100"><i className="fa-brands fa-fw fa-telegram"></i>Join LocaGo on Telegram</a>
                            {/* <a href="https://www.facebook.com/LocaMosGlobalAg" target="_blank"><i className="fa-brands fa-fw fa-square-facebook"></i></a>
                            <a href="https://twitter.com/@LocaMosGlobalAg" target="_blank"><i className="fa-brands fa-fw fa-x-twitter"></i></a>
                            <a href="https://www.instagram.com/" target="_blank"><i className="fa-brands fa-fw fa-instagram"></i></a>
                            <a href="https://www.linkedin.com/company/joinlocamos/" target="_blank"><i className="fa-brands fa-fw fa-linkedin"></i></a>
                            <a href="https://www.youtube.com/@joinlocamos" target="_blank"><i className="fa-brands fa-fw fa-youtube"></i></a> */}
                        </div>
					</div>
				</div>
				<div id="copyright" className="site-copyright">
					<div className="row">
						<div className="col-md-6 text-center text-md-start mb-3 mb-md-0 fw-semibold">
							&copy; 2024 LCM Technology PTE.
							All rights reserved.
						</div>
						<div className="col-md-6 text-center text-md-end">
							<Link to={NAVIGATION.PRIVACY_POLICY} className="me-4">{text.footer.links.privacy_policy[lang]}</Link>
							<Link to={NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
});

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}