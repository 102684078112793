import React from 'react';
import './ContentPages.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';

import { NAVIGATION } from 'config';

import { ScreenWithNavBarAndFooter } from 'app/components';

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const TermsOfService = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;

    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">{pageInfo.company_shortname} Terms of Use</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li><a href="#introduction">Introduction</a></li>
                            <li><a href="#theService">The {pageInfo.company_shortname} Service Provided by Us</a></li>
                            <li><a href="#yourUseOfService">Your Use of the {pageInfo.company_shortname} Service</a></li>
                            <li><a href="#problemsAndDisputes">Problems and Disputes</a></li>
                            <li><a href="#aboutTheseTerms">About these Terms</a></li>
                        </ol>
                        <hr />
                        <div id="introduction">
                            <h2>1. Introduction</h2>
                            <p>
                                Please read these Terms of Use (these "Terms") carefully as they govern your use of (which includes access to) {pageInfo.company_shortname}&apos;s personalized services for publishing virtual tours and other content, including all of our websites and software applications that incorporate or link to these Terms (collectively, the &quot;{pageInfo.company_shortname} Service&quot;) and any photos, texts, or other material that is made available through the {pageInfo.company_shortname} Service (the &quot;Content&quot;).<br />
                                Use of the {pageInfo.company_shortname} Service may be subject to additional terms and conditions presented by {pageInfo.company_shortname}, which are hereby incorporated by this reference into these Terms.<br />
                                By signing up for, or otherwise using, the {pageInfo.company_shortname} Service, you agree to these Terms. If you do not agree to these Terms, then you must not use the {pageInfo.company_shortname} Service or access any Content.
                            </p>
                            <h5 className="text-primary">Service Provider</h5>
                            <p>These Terms are between you and {pageInfo.company_shortname}.</p>
                            <h5 className="text-primary">Age and eligibility requirements</h5>
                            <p>In order to use the {pageInfo.company_shortname} Service and access any Content, you need to (1) be 13 years of age (or the equivalent minimum age in your home country) or older, (2) have parent or guardian consent if you are a minor in your home country; (3) have the power to enter a binding contract with us and not be barred from doing so under any applicable laws, and (4) reside in a country where the Service is available. You also promise that any registration information that you submit to {pageInfo.company_shortname} is true, accurate, and complete, and you agree to keep it that way at all times If you are a minor in your home country, your parent or guardian will need to enter into these Terms on your behalf. You can find additional information regarding minimum age requirements in the registration process. If you do not meet the minimum age requirements then {pageInfo.company_shortname} will be unable to register you as a user.</p>
                        </div>
                        <div id="theService">
                            <h2>2. The {pageInfo.company_shortname} Service Provided by Us</h2>
                            <h5 className="text-primary">{pageInfo.company_shortname} Service Options</h5>
                            <p>We provide certain {pageInfo.company_shortname} Service options. We may also offer special promotional plans, memberships, or services, including offerings of third-party products and services. We are not responsible for the products and services provided by such third parties.</p>
                            <h5 className="text-primary">Third-Party Applications, Devices and Open Source Software</h5>
                            <p>The {pageInfo.company_shortname} Service may be integrated with, or may otherwise interact with, third-party applications, websites, and services (&quot;Third-Party Applications&quot;) and third-party personal cameras and other devices (&quot;Devices&quot;). Your use of such Third-Party Applications and Devices may be subject to additional terms, conditions and policies provided to you by the applicable third party. {pageInfo.company_shortname} does not guarantee that Third-Party Applications and Devices will be compatible with the {pageInfo.company_shortname} Service.</p>
                            <h5 className="text-primary">Service Limitations and Modifications</h5>
                            <p>We use reasonable care and skill to keep the {pageInfo.company_shortname} Service operational and to provide you with a personalized experience. However, our service offerings and their availability may change from time to time and subject to applicable laws, without liability to you; for example:</p>
                            <ul>
                                <li>The {pageInfo.company_shortname} Services may experience temporary interruptions due to technical difficulties, maintenance or testing, or updates, including those required to reflect changes in relevant laws and regulatory requirements.</li>
                                <li>We aim to evolve and improve our Services constantly, and we may modify, suspend, or stop (permanently or temporarily) providing all of part of the {pageInfo.company_shortname} Service (including particular functions, features, and promotional offerings).</li>
                                <li>{pageInfo.company_shortname} has no obligation to provide any specific content through the {pageInfo.company_shortname} Service, and {pageInfo.company_shortname} or the applicable owners may remove photos, virtual tours and other Content without notice.</li>
                            </ul>
                            <p>{pageInfo.company_shortname} has no liability to you, nor any obligation to provide a refund to you, in connection with internet or other service outages or failures that are caused by the <span className="text-primary">actions of government authorities, other third parties or events beyond our control.</span></p>
                        </div>
                        <div id="yourUseOfService">
                            <h2>3. Your Use of the {pageInfo.company_shortname} Service</h2>
                            <h5 className="text-primary">Creating a {pageInfo.company_shortname} account</h5>
                            <p>
                                You may need to create a {pageInfo.company_shortname} account to use all or part of the {pageInfo.company_shortname} Service. Your username and password are for your personal use only and should be kept confidential. You understand that you are responsible for all use (including any unauthorized use) of your username and password. Notify our Customer Service team immediately if your username or password is lost or stolen, or if you believe there has been unauthorized access to your account.<br />
                                {pageInfo.company_shortname} may reclaim, or require you to change, your username for any reason.
                            </p>
                            <h5 className="text-primary">Your rights to use the {pageInfo.company_shortname} Service</h5>
                            <h5 className="text-primary">User Guidelines</h5>
                            <p>We&apos;ve established guidelines for using the {pageInfo.company_shortname} Service, to make sure the {pageInfo.company_shortname} Service stays enjoyable for everyone (&quot;{pageInfo.company_shortname} User Guidelines&quot;). In using the {pageInfo.company_shortname} Service, you must comply with the <span className="text-primary">{pageInfo.company_shortname} User Guidelines</span>, as well as all applicable laws, rules, and regulation, and respect the intellectual property, privacy, and other rights of third parties.</p>
                        </div>
                        <div id="problemsAndDisputes">
                            <h2>4. Problems and Disputes</h2>
                            <h5 className="text-primary">Suspending and terminating the {pageInfo.company_shortname} Services</h5>
                            <p>These Terms will continue to apply to you until terminated by either you or {pageInfo.company_shortname}. {pageInfo.company_shortname} may terminate these Terms (including any additional terms and conditions incorporated herein) or suspend your access to the {pageInfo.company_shortname} Service at any time if we believe you have breached any of these Terms, if we stop providing the {pageInfo.company_shortname} Service or any material component thereof on reasonable notice to you, or as we believe necessary to comply with applicable law. If you or {pageInfo.company_shortname} terminate these Terms, or if {pageInfo.company_shortname} suspends your access to the {pageInfo.company_shortname} Service, you agree that {pageInfo.company_shortname} shall, subject to applicable laws, have no liability or responsibility to you.</p>
                            <h5 className="text-primary">Third Party Rights</h5>
                            <p>You acknowledge and agree that the owners of the Content and certain distributors (such as app store providers) are intended beneficiaries of these Terms and have the right to enforce these Terms directly against you. Other than as set out in this section, these Terms are not intended to grant rights to anyone except you and {pageInfo.company_shortname}, and in no event shall these Terms create any third-party beneficiary rights.</p>
                            <h4 className="text-primary">Indemnification</h4>
                            <p>You agree to indemnify and hold {pageInfo.company_shortname} harmless from and against any reasonably foreseeable direct losses, damages, and reasonable expenses (including reasonable attorney fees and costs) suffered or incurred by {pageInfo.company_shortname} arising out of or related to: (1) your breach of any of these Terms (including any additional {pageInfo.company_shortname} terms and conditions incorporated herein); (2) any User Content you post or otherwise contribute; (3) any activity in which you engage on or through the {pageInfo.company_shortname} Service; and (4) your violation of any law or the rights of a third party.</p>
                            <h4 className="text-primary">Governing Law, Mandatory Arbitration and Venue</h4>
                            <h5 className="text-primary">Governing Law / Jurisdiction</h5>
                            <p>
                                Unless otherwise required by mandatory laws in your country of residence, the Agreements (and any non-contractual disputes/claims arising out of or in connection with them) are subject to the laws of the state or country listed below, without regard to choice or conflicts of law principles.<br />
                                Further, you and {pageInfo.company_shortname} agree to the jurisdiction of the courts listed below to resolve any dispute, claim, or controversy that arises in connection with the Agreements (and any non-contractual disputes/claims arising out of or in connection with them), except where under applicable mandatory laws, you can choose to bring legal proceedings in your country of residence, or we are required to only bring legal proceedings in your country of residence.
                            </p>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-warning text-dark">
                                            <th>Country or region</th>
                                            <th>Choice of Law</th>
                                            <th>Jurisdiction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>All other countries and regions in which {pageInfo.company_shortname} is available.</td>
                                            <td>Sweden</td>
                                            <td>Exclusive; Courts of Sweden</td>
                                        </tr>
                                        <tr>
                                            <td>Bulgaria, Cyprus, Estonia, France, Hong Kong, Latvia, Lithuania, Luxembourg, Malta, Monaco, Norway, Philippines, Portugal, Slovakia, Spain, Turkey</td>
                                            <td>Laws of Sweden</td>
                                            <td>Non-exclusive; Courts of Sweden</td>
                                        </tr>
                                        <tr>
                                            <td>Brazil</td>
                                            <td>Laws of Brazil</td>
                                            <td>Exclusive; State and Federal Courts of São Paulo, State of São Paulo, Brazil</td>
                                        </tr>
                                        <tr>
                                            <td>Canada</td>
                                            <td>Not applicable to residents of Quebec: Laws of the Province of OntarioResidents of Quebec: Laws of the Province of Quebec, Canada</td>
                                            <td>Not applicable to residents of Quebec: Exclusive other than for the purpose of enforcing judgements; Courts of Ontario, CanadaResidents of Quebec: Courts of Quebec, Canada</td>
                                        </tr>
                                        <tr>
                                            <td>Argentina, Bolivia, Chile, Colombia, Costa Rica, Dominican Republic, Ecuador, El Salvador, Guatemala, Honduras, Nicaragua, Panama, Paraguay, Peru, Uruguay</td>
                                            <td>State of California, United States</td>
                                            <td>Exclusive; State and Federal Courts of San Francisco County, CA or New York, NY</td>
                                        </tr>
                                        <tr>
                                            <td>United Kingdom</td>
                                            <td>Laws of England and Wales</td>
                                            <td>Exclusive</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div id="aboutTheseTerms">
                            <h2>5. About these Terms</h2>
                            <p>Under applicable law, you may have certain rights that can&apos;t be limited by a contract. These terms are in no way intended to restrict those rights.</p>
                            <h5 className="text-primary">Changes</h5>
                            <p>We may make changes to these Terms (including any additional {pageInfo.company_shortname} terms and conditions incorporated by reference herein) from time to time by notifying you of such changes by any reasonable means (before they take effect), including by posting a revised Agreement on the applicable {pageInfo.company_shortname} Service (provided that, for material changes, we will seek to supplement such notice by email, an in-service pop-up message, or other means). Any such changes will not apply to any dispute between you and us arising prior to the date on which we posted the revised Terms, or other {pageInfo.company_shortname} terms and conditions, incorporating such changes, or otherwise notified you of such changes. Your use of the {pageInfo.company_shortname} Service following any changes to these Terms will constitute your acceptance of such changes. If you do not wish to continue using the {pageInfo.company_shortname} Service under the updated Terms, you may terminate your account by contacting us. The effective date set forth at the top of this document indicates when these Terms were last changed.</p>
                            <h5 className="text-primary">Entire Agreement</h5>
                            <p>Other than as stated in this section or as explicitly agreed upon in writing between you and {pageInfo.company_shortname}, these Terms constitute all the terms and conditions agreed upon between you and {pageInfo.company_shortname} and supersede any prior agreements in relation to the subject matter of these Terms, whether written or oral.</p>
                            <h5 className="text-primary">Severability and Waiver</h5>
                            <p>
                                Unless as otherwise stated in these Terms, should any provision of these Terms be held invalid or unenforceable for any reason or to any extent, the remaining provisions of these Terms will not be affected, and the application of that provision shall be enforced to the extent permitted by law.<br />
                                Any failure by {pageInfo.company_shortname} or any third-party beneficiary to enforce these Terms or any provision thereof shall not waive {pageInfo.company_shortname}&apos;s or the applicable third-party beneficiary&apos;s right to do so.
                            </p>
                            <h5 className="text-primary">Assignment</h5>
                            <p>{pageInfo.company_shortname} may assign any or all of these Terms, and may assign or delegate, in whole or in part, any of its rights or obligations under these Terms. You may not assign these Terms, in whole or in part, nor transfer or sub-license your rights under these Terms, to any third party.</p>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const PrivacyPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">{pageInfo.company_shortname} Privacy Policy</h2>
                    <p className="sec-description ms-0">
                        Your Privacy is important. Last update: November 18, 2021<br />
                        Effective as of 1 November 2021
                    </p>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li><a href="#section1">About this Policy</a></li>
                            <li><a href="#section2">Your personal data rights and controls</a></li>
                            <li><a href="#section3">Personal data we collect about you</a></li>
                            <li><a href="#section4">Our purpose for using your personal data</a></li>
                            <li><a href="#section5">Sharing your personal data</a></li>
                            <li><a href="#section6">Data retention and deletion</a></li>
                            <li><a href="#section7">Transfer to other countries</a></li>
                            <li><a href="#section8">Keeping your personal data safe</a></li>
                            <li><a href="#section9">Children</a></li>
                            <li><a href="#section10">Changes to this Policy</a></li>
                            <li><a href="#section11">How to contact us</a></li>
                        </ol>
                        <hr />
                        <div id="section1">
                            <h2>1. About this Policy</h2>
                            <p>
                                This Policy describes how we process your personal data at {pageInfo.company_shortname}.<br />
                                It applies to your use of:
                            </p>
                            <ul>
                                <li>all {pageInfo.company_shortname} services as a user. This includes use of these services under our free or paid options (each a &quot;Service Option&quot;); and</li>
                                <li>other {pageInfo.company_shortname} services which include a link to this Privacy Policy (e.g. {pageInfo.company_shortname} websites, Customer Support and the Community Site).</li>
                            </ul>  
                            <p>                          
                                From now on, we&apos;ll collectively call these the &quot;{pageInfo.company_shortname} Service&quot;.<br />
                                From time to time, we may develop new or offer additional services. They&apos;ll also be subject to this Policy, unless stated otherwise when we introduce them.<br />
                                This Policy is not...
                            </p>
                            <ul>
                                <li>the {pageInfo.company_shortname} <span className="text-primary">Terms of Use</span>. That&apos;s a separate document, outlining the legal contract between you and {pageInfo.company_shortname} for using the {pageInfo.company_shortname} Service. It also describes the rules of {pageInfo.company_shortname} and your user rights.</li>
                                <li>about your use of other {pageInfo.company_shortname} services which have their own dedicated privacy policy.</li>
                            </ul>
                        </div>
                        <div id="section2">
                            <h2>2. Your personal data rights and controls</h2>
                            <p>
                                Privacy laws, including the General Data Protection Regulation (&quot;GDPR&quot;), give rights to individuals over their personal data.<br />
                                See your rights and their descriptions in this table.
                            </p>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-warning text-dark">
                                            <th></th>
                                            <th>It&apos;s your right to...</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Access</td>
                                            <td>Be informed of the personal data we process about you and to request access to it</td>
                                        </tr>
                                        <tr>
                                            <td>Rectification</td>
                                            <td>Request that we amend or update your personal data where it&apos;s inaccurate or incomplete</td>
                                        </tr>
                                        <tr>
                                            <td>Erasure</td>
                                            <td>Request that we delete certain of your personal data</td>
                                        </tr>
                                        <tr>
                                            <td>Restriction</td>
                                            <td>Request that we temporarily or permanently stop processing all or some of your personal data</td>
                                        </tr>
                                        <tr>
                                            <td>Object</td>
                                            <td>Object to us processing your personal data at any time, on grounds relating to your particular situation<br/>
                                                Object to your personal data being processed for direct marketing purposes</td>
                                        </tr>
                                        <tr>
                                            <td>Data portability</td>
                                            <td>Request a copy of your personal data in electronic format and the right to transmit that personal data for use in another party&apos;s service</td>
                                        </tr>
                                        <tr>
                                            <td>Not be subject to automated decision-making</td>
                                            <td>Not be subject to a decision based solely on automated decision making (decisions without human involvement), including profiling, where the decision would have a legal effect on you or produce a similarly significant effect</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>
                                How to exercise your rights with {pageInfo.company_shortname}<br />
                                Access: To request a copy of your personal data from {pageInfo.company_shortname}, visit your account <span className="text-primary">Privacy Settings</span>. There you can automatically download much of your personal data and find out how to request more information.<br />
                                Rectification: You can edit your User Data under &quot;Edit profile&quot; in your account.<br />
                                Erasure: To request erasure of your other personal data from {pageInfo.company_shortname} (e.g. your User Data, Usage Data and other data listed in Section 3 &apos;Personal data we collect about you&apos; below), please email us at <span className="text-primary">{pageInfo.email}</span>.<br />
                                Your other rights: You can contact {pageInfo.company_shortname} directly to exercise your rights at any time (see Section 11 &apos;How to contact us&apos;).<br />
                                In addition, here are more privacy resources and controls:
                            </p>
                            <ul>
                                <li>Privacy Center - more information about how {pageInfo.company_shortname} uses your personal data, your rights, and how to use those rights.</li>
                                <li>Privacy Settings - control the processing of certain personal data, including Tailored Ads.</li>
                                <li>Notification Settings - learn how to set which marketing communications you get from {pageInfo.company_shortname}.</li>
                                <li>Settings (Mobile application) - control certain aspects of the {pageInfo.company_shortname} Service.</li>
                                <li>Cookies Policy - information on how we use cookies and how to manage your cookie preferences. Cookies are files saved on your phone, tablet or computer when you visit a website.</li>
                            </ul>
                        </div>
                        <div id="section3">
                            <h2>3. Personal data we collect about you</h2>
                            <p>These tables set out the categories of personal data we collect and use. You can also watch our video about <span className="text-primary">Personal Data at {pageInfo.company_shortname}</span>.</p>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="bg-warning text-dark">
                                            <th colSpan="2">Collected when you sign up for the {pageInfo.company_shortname} Service or when you update your account</th>
                                        </tr>
                                        <tr>
                                            <td>Category</td>
                                            <td>Description</td>
                                        </tr>
                                        <tr>
                                            <td>User Data</td>
                                            <td>
                                                <p>
                                                    Personal data that we need to create your {pageInfo.company_shortname} account and enables you to use the {pageInfo.company_shortname} Service.<br />
                                                    The type of data collected depends on the type of Service Option you have, how you create your account, the country you are in, and if you use third party services to sign in. This may include your:
                                                </p>
                                                <ul>
                                                    <li>profile name</li>
                                                    <li>email address</li>
                                                    <li>phone number</li>
                                                    <li>date of birth</li>
                                                    <li>gender</li>
                                                    <li>street address</li>
                                                    <li>country</li>
                                                </ul>
                                                We receive some of this data from you e.g. from the sign up form or account page.<br />
                                                We also collect some of this data from your device e.g. country. For more information about how we collect country, see “Your general (non-precise) location”.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Street Address Data</td>
                                            <td>
                                                <p>We may ask for your street address for the following reasons:</p>
                                                <ul>
                                                    <li>to check eligibility for a Service Option</li>
                                                    <li>to deliver physical goods or gifts requested by you</li>
                                                </ul>
                                                In some cases, we may use a third party mapping application (such as Google Maps) to help you verify your address.
                                            </td>
                                        </tr>
                                        <tr className="bg-warning text-dark">
                                            <th colSpan="2">Collected through your use of the {pageInfo.company_shortname} Service</th>
                                        </tr>
                                        <tr>
                                            <td>Category</td>
                                            <td>Description</td>
                                        </tr>
                                        <tr>
                                            <td>Usage Data</td>
                                            <td>
                                                <p>
                                                    Personal data collected about you when you&apos;re accessing and/or using the {pageInfo.company_shortname} Service.<br />
                                                    There are a few types of information this includes, detailed in the following sections.<br />
                                                    <br />
                                                    Information about how you use {pageInfo.company_shortname}<br />
                                                    Examples include:
                                                </p>
                                                <ul>
                                                    <li>Information about your {pageInfo.company_shortname} Service Option.</li>
                                                    <li>
                                                        Your actions with the {pageInfo.company_shortname} Service (including date and time), such as:
                                                        <ul>
                                                            <li>search queries</li>
                                                            <li>lists you create</li>
                                                            <li>your library</li>
                                                            <li>browsing history</li>
                                                            <li>account settings</li>
                                                            <li>interactions with other {pageInfo.company_shortname} users</li>
                                                            <li>your use of third party services, devices and applications in connection with the {pageInfo.company_shortname} Service</li>
                                                        </ul>
                                                    </li>
                                                    <li>Inferences (i.e., our understanding) of your interests and preferences based on your usage of the {pageInfo.company_shortname} Service.</li>
                                                    <li>Content you post to any part of the {pageInfo.company_shortname} Service, such as images, text, titles, communications, and other types of content.</li>
                                                </ul>
                                                <p>
                                                    Your technical data<br />
                                                    Examples include:
                                                </p>
                                                <ul>
                                                    <li>URL information</li>
                                                    <li>Online identifiers such as <span className="text-primary">cookie data</span> and IP addresses</li>
                                                    <li>
                                                        Information about the devices you use such as:
                                                        <ul>
                                                            <li>device IDs</li>
                                                            <li>network connection type (e.g. wifi, 4G, LTE, Bluetooth)</li>
                                                            <li>provider</li>
                                                            <li>network and device performance</li>
                                                            <li>browser type</li>
                                                            <li>language</li>
                                                            <li>information enabling digital rights management</li>
                                                            <li>operating system</li>
                                                            <li>{pageInfo.company_shortname} application version</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        Information (such as the device name, device identifiers, brand and version) which enables us to discover and connect with third party devices and applications such as those listed below:
                                                        <ul>
                                                            <li>devices on your wifi network (such as camera) which can connect to the {pageInfo.company_shortname} Service</li>
                                                            <li>devices made available by your operating system when connecting via Bluetooth, plugin, and installation</li>
                                                            <li>{pageInfo.company_shortname} partner applications to determine whether the application is installed on your device</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                Your general (non-precise) location<br />
                                                This may be understood from technical data (e.g. your IP address, language setting of your device, or payment currency).<br />
                                                We need this to meet geographic requirements in our agreements with the owners of content on the {pageInfo.company_shortname} Service, and to deliver content and advertising that&apos;s relevant to you.<br />
                                                Your device sensor data<br />
                                                Motion-generated or orientation-generated mobile sensor data (e.g. accelerometer or gyroscope) if needed to provide features of the {pageInfo.company_shortname} Service that require this data.
                                            </td>
                                        </tr>
                                        <tr className="bg-warning text-dark">
                                            <th colSpan="2">Additional data you may choose to give us</th>
                                        </tr>
                                        <tr>
                                            <td>Categories</td>
                                            <td>Description</td>
                                        </tr>
                                        <tr>
                                            <td>Voice Data</td>
                                            <td>
                                                If voice features are available in your market and only where you&apos;ve given permission, we collect voice data (audio clips of your voice).<br />
                                                This makes it possible for us to provide and improve {pageInfo.company_shortname}&apos;s voice features.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Contests, Surveys, and Sweepstakes Data</td>
                                            <td>When you complete any forms, respond to a survey or questionnaire, or take part in a contest or sweepstake, we collect the personal data you provide.</td>
                                        </tr>
                                        <tr className="bg-warning text-dark">
                                            <th colSpan="2">Collected from other (&apos;third party&apos;) sources</th>
                                        </tr>
                                        <tr>
                                            <td>Categories of third parties</td>
                                            <td>Description</td>
                                        </tr>
                                        <tr>
                                            <td>Authentication partners</td>
                                            <td>If you register for or log into our services using another service, we&apos;ll receive your information from them to help create your account with us.</td>
                                        </tr>
                                        <tr>
                                            <td>Third party applications, services and devices you connect to your {pageInfo.company_shortname} account</td>
                                            <td>
                                                <p>
                                                    If you connect your {pageInfo.company_shortname} account to a third party application, service and/or devices, we may collect certain information from them to make the integration possible.<br />
                                                    These third party apps, services or devices may include:
                                                </p>
                                                <ul>
                                                    <li>social media</li>
                                                    <li>
                                                        devices including:
                                                        <ul>
                                                            <li>audio (e.g. speakers and headphones)</li>
                                                            <li>smart watches</li>
                                                            <li>televisions</li>
                                                            <li>mobile phones and tablets</li>
                                                            <li>automotive (e.g. cars)</li>
                                                            <li>games consoles</li>
                                                        </ul>
                                                    </li>
                                                    <li>services or platforms such as voice assistants</li>
                                                </ul>
                                                We&apos;ll ask your permission before we collect your information from certain third parties.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Technical service partners</td>
                                            <td>
                                                We work with technical service partners that give us certain data, such as mapping IP addresses to non-precise location data (e.g., city, state).<br />
                                                This makes it possible for {pageInfo.company_shortname} to provide the {pageInfo.company_shortname} Service, content, and features.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Advertising and marketing partners</td>
                                            <td>
                                                <p>
                                                    We may get data about you from certain advertising or marketing partners.<br />
                                                    This may include:
                                                </p>
                                                <ul>
                                                    <li>cookie id</li>
                                                    <li>mobile device id</li>
                                                    <li>email address</li>
                                                    <li>inferences (i.e., our understanding) of your interests and preferences</li>
                                                </ul>
                                                This allows us to deliver more relevant ads and marketing.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div id="section4">
                            <h2>4. Our purpose for using your personal data</h2>
                            <p>The table below sets out:</p>
                            <ul>
                                <li>our purpose for processing your personal data</li>
                                <li>our legal justifications (each called a &quot;legal basis&quot;) under data protection law, for each purpose</li>
                                <li>categories of personal data which we use for each purpose (see more about these categories in Section 3 &apos;Personal data we collect about you&apos;)</li>
                            </ul>
                            <p>Here is a general explanation of each &quot;legal basis&quot; to help you understand the table:</p>
                            <ul>
                                <li>
                                    Performance of a Contract: When it&apos;s necessary for {pageInfo.company_shortname} (or a third party) to process your personal data to:
                                    <ul>
                                        <li>comply with obligations under a contract with you, e.g. {pageInfo.company_shortname}&apos;s obligations under the Terms of Use to provide the {pageInfo.company_shortname} Service to you</li>
                                        <li>or verify information before a new contract with you begins.</li>
                                    </ul>
                                </li>
                                <li>Legitimate Interest: When {pageInfo.company_shortname} (or a third party) has an interest in using your personal data in a certain way, which is necessary and justified considering any possible risks to you and other {pageInfo.company_shortname} users. For example, using your Usage Data to improve the {pageInfo.company_shortname} Service for all users. Contact us if you want to understand a specific justification.</li>
                                <li>Consent: When {pageInfo.company_shortname} asks you to actively indicate your agreement to {pageInfo.company_shortname}&apos;s use of your personal data for a certain purpose(s).</li>
                                <li>Compliance with Legal Obligations: When {pageInfo.company_shortname} must process your personal data to comply with a law.</li>
                            </ul>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-warning text-dark">
                                            <th>Purpose for processing your data</th>
                                            <th>Legal basis that permits the purpose</th>
                                            <th>Categories of personal data used for the purpose</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>To provide the personalized {pageInfo.company_shortname} Service.</td>
                                            <td>
                                                <ul>
                                                    <li>Performance of a Contract</li>
                                                    <li>Legitimate Interest</li>
                                                    <li>Consent</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Payment and Purchase Data</li>
                                                    <li>Street Address Data</li>
                                                    <li>Voice Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>To understand, diagnose, troubleshoot, and fix issues with the {pageInfo.company_shortname} Service.</td>
                                            <td>
                                                <ul>
                                                    <li>Performance of a Contract</li>
                                                    <li>Legitimate Interest</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>To evaluate and develop new features, technologies, and improvements to the {pageInfo.company_shortname} Service.</td>
                                            <td>
                                                <ul>
                                                    <li>Legitimate Interest</li>
                                                    <li>Consent</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Voice Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>For marketing, promotion, and advertising purposes.</td>
                                            <td>
                                                <ul>
                                                    <li>Legitimate Interest</li>
                                                    <li>Consent</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Contests, Surveys and Sweepstakes Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>To comply with legal obligations and law enforcement requests.</td>
                                            <td>
                                                <ul>
                                                    <li>Compliance with legal obligations</li>
                                                    <li>Legitimate interest</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Payment and Purchase Data</li>
                                                    <li>Street Address Data</li>
                                                    <li>Voice Data</li>
                                                    <li>Contests, Surveys and Sweepstakes Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                To:
                                                <ul>
                                                    <li>fulfill contractual obligations with third parties, for example our agreements with owners of content on the {pageInfo.company_shortname} Service, and</li>
                                                    <li>take appropriate action with reports of intellectual property infringement and inappropriate content.</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>Legitimate interest</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Payment and Purchase Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>To establish, exercise, or defend legal claims.</td>
                                            <td>
                                                <ul>
                                                    <li>Legitimate interest</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Payment and Purchase Data</li>
                                                    <li>Street Address Data</li>
                                                    <li>Contests, Surveys and Sweepstakes Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>To conduct business planning, reporting, and forecasting.</td>
                                            <td>
                                                <ul>
                                                    <li>Legitimate Interest</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Payment and Purchase Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>To detect and prevent fraud, including fraudulent payments and fraudulent use of the {pageInfo.company_shortname} Service.</td>
                                            <td>
                                                <ul>
                                                    <li>Performance of a Contract</li>
                                                    <li>Compliance with legal obligations</li>
                                                    <li>Legitimate Interest</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Payment and Purchase Data</li>
                                                    <li>Street Address Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>To conduct research, contests, surveys, and sweepstakes.</td>
                                            <td>
                                                <ul>
                                                    <li>Performance of a Contract</li>
                                                    <li>Legitimate Interest</li>
                                                    <li>Consent</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>User Data</li>
                                                    <li>Usage Data</li>
                                                    <li>Contests, Surveys and Sweepstakes Data</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div id="section5">
                            <h2>5. Sharing your personal data</h2>
                            <p>
                                This section sets out the categories of recipients of the personal data collected or generated through your use of the {pageInfo.company_shortname} Service.<br />
                                <br />
                                Publicly available information<br />
                                The following personal data will always be publicly available on the {pageInfo.company_shortname} Service:
                            </p>
                            <ul>
                                <li>Your profile name</li>
                                <li>Your profile photo</li>
                                <li>Your public lists</li>
                                <li>Who you follow on the {pageInfo.company_shortname} Service</li>
                                <li>Who follows you on the {pageInfo.company_shortname} Service (you can remove followers)</li>
                            </ul>
                            <p>
                                Personal data you may choose to share<br />
                                We will only share the following personal data with those outlined in the table below:
                            </p>
                            <ul>
                                <li>where sharing of particular personal data is needed for the use of a {pageInfo.company_shortname} Service feature, or a third party application, service or device, which you have chosen to use; or</li>
                                <li>if you otherwise grant us your permission to share the personal data, e.g. by selecting the appropriate setting in the {pageInfo.company_shortname} Service or by giving your consent</li>
                            </ul>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-warning text-dark">
                                            <th>Categories of recipient</th>
                                            <th>Reason for sharing</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Third party applications, services and devices you connect to your {pageInfo.company_shortname} Account</td>
                                            <td>
                                                To connect your {pageInfo.company_shortname} account, and/or allow you to use the {pageInfo.company_shortname} Service in connection with third party applications, services or devices.<br />
                                                Examples of such third party applications, services and devices include: social media applications, speaker devices, televisions, automotive platforms, or voice assistants, which interact with the {pageInfo.company_shortname} Service.<br />
                                                You can see and remove many third party connections under “Apps” in your account.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Support community</td>
                                            <td>
                                                To enable you to use the {pageInfo.company_shortname} Support Community service.<br />
                                                When you register for an account on the {pageInfo.company_shortname} Support Community, we&apos;ll ask you to create a specific profile name. This will be publicly displayed to anyone who uses the {pageInfo.company_shortname} Support Community. We&apos;ll also display any questions or comments you post.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Other {pageInfo.company_shortname} users</td>
                                            <td>To share information about your use of the {pageInfo.company_shortname} Service with other {pageInfo.company_shortname} users, including your &apos;{pageInfo.company_shortname} Followers&apos;.</td>
                                        </tr>
                                        <tr>
                                            <td>Artists and record labels</td>
                                            <td>
                                                To receive news or promotional offers directly from artists, record labels or other partners.<br />
                                                You may choose to share personal data (for example, your email address) for this purpose. You&apos;ll always have the option to change your mind and withdraw your consent at any time.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>
                                Information we may share<br />
                                See this table for details of who we share to and why.
                            </p>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-warning text-dark">
                                            <th>Categories of recipient</th>
                                            <th>Reason for sharing</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Service providers</td>
                                            <td>
                                                <p>
                                                    So they can provide their services to {pageInfo.company_shortname}.<br />
                                                    These service providers may include those we hire to:
                                                </p>
                                                <ul>
                                                    <li>give customer support</li>
                                                    <li>operate the technical infrastructure we need to provide the {pageInfo.company_shortname} Service</li>
                                                    <li>assist in protecting and securing our systems and services</li>
                                                    <li>help market {pageInfo.company_shortname}&apos;s (and our partners&apos;) products, services, events and promotions</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Advertising partners</td>
                                            <td>
                                                <p>
                                                    So our ad partners can help us deliver more relevant advertising to you on the {pageInfo.company_shortname} Service.<br />
                                                    For example, our ad partners help us facilitate tailored advertising:
                                                </p>
                                                <ul>
                                                    <li>This is when we use third party information to tailor ads to be more relevant to you (also known as interest based advertising).</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Marketing Partners</td>
                                            <td>
                                                <p>To promote {pageInfo.company_shortname} with our partners. We share some personal data with these partners where necessary to:</p>
                                                <ul>
                                                    <li>enable you to participate in {pageInfo.company_shortname} promotions, including trials or other bundled offers</li>
                                                    <li>to promote {pageInfo.company_shortname} in media and advertising published on other online services</li>
                                                    <li>help us and our partners to measure the effectiveness of {pageInfo.company_shortname} promotions</li>
                                                </ul>
                                                <p>Examples of partners include:</p>
                                                <ul>
                                                    <li>marketing or sponsorship partners</li>
                                                    <li>websites and mobile apps who sell us advertising space on their services</li>
                                                    <li>device, app and mobile partners who also offer {pageInfo.company_shortname} promotions</li>
                                                </ul>
                                                Our partners may also combine the personal data we share with them with other data they collect about you (e.g. your use of their services). We and our partners may use this information to present you with offers, promotions, or other marketing activities that we believe will be relevant to you.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Other partner sharing</td>
                                            <td>We may share personal data with partners to help us understand and improve the performance of our products and partnerships.</td>
                                        </tr>
                                        <tr>
                                            <td>Academic researchers</td>
                                            <td>For activities such as statistical analysis and academic study, but only in a pseudonymised format. Pseudonymised data is where your data is identified by a code rather than your name or other information that directly identifies you.</td>
                                        </tr>
                                        <tr>
                                            <td>Other {pageInfo.company_shortname} group companies</td>
                                            <td>Where we have offices, to carry out our daily business operations and so we can maintain and provide the {pageInfo.company_shortname} Service to you.</td>
                                        </tr>
                                        <tr>
                                            <td>Law enforcement and other authorities</td>
                                            <td>
                                                <p>When we believe in good faith it&apos;s necessary for us to do so, for example:</p>
                                                <ul>
                                                    <li>to comply with a legal obligation</li>
                                                    <li>to respond to a valid legal process (such as a search warrant, court order, or subpoena)</li>
                                                    <li>
                                                        for our own or a third party&apos;s justifiable interest, relating to:
                                                        <ul>
                                                            <li>national security</li>
                                                            <li>law enforcement</li>
                                                            <li>litigation (a court case)</li>
                                                            <li>criminal investigation</li>
                                                            <li>protecting someone&apos;s safety</li>
                                                            <li>preventing death or imminent bodily harm.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Purchasers of our business</td>
                                            <td>
                                                Where we sell or negotiate to sell our business to a buyer or possible buyer.<br />
                                                In this situation, {pageInfo.company_shortname} will continue to ensure your personal data is kept confidential. We&apos;ll give you notice before your personal data is transferred to the buyer or a different privacy policy starts to apply.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div id="section6">
                            <h2>6. Data retention and deletion</h2>
                            <p>
                                <b>Retention</b><br />
                                We keep your personal data only as long as necessary to provide you with the {pageInfo.company_shortname} Service and for {pageInfo.company_shortname}&apos;s legitimate and essential business purposes, such as:
                            </p>
                            <ul>
                                <li>maintaining the performance of the {pageInfo.company_shortname} Service</li>
                                <li>making data-driven business decisions about new features and offerings</li>
                                <li>complying with our legal obligations</li>
                                <li>resolving disputes</li>
                            </ul>
                            <p id="deleteaccount">
                                <b>Delete your account</b><br />
                                If you close or request that we close your account, we&apos;ll delete or anonymise your personal data so it no longer identifies you, unless we&apos;re required to keep something or we still need to use it for a legally justifiable reason.<br />
                                Here are some examples of situations where we&apos;re legally allowed or required to keep some of your personal data:
                            </p>
                            <ul>
                                <li>if there&apos;s an unresolved issue relating to your account, such as an outstanding credit or unresolved claim or dispute</li>
                                <li>for our legal, tax, audit and accounting obligations</li>
                                <li>for our legitimate business interests such as fraud prevention or to maintain security.</li>
                            </ul>
                            <p>To delete your account, please go to the <b>User</b> tab, open <b>Settings</b>, then press <b>Delete the account</b> and confirm the deletion of your account.</p>
                            <div className="row">
                                <div className="col-md-4">
                                    <img className="img-fluid" src="/public_assets/images/content-pages/deleteacc-1.png" alt=""></img>
                                </div>
                                <div className="col-md-4">
                                    <img className="img-fluid" src="/public_assets/images/content-pages/deleteacc-2.png" alt=""></img>
                                </div>
                                <div className="col-md-4">
                                    <img className="img-fluid" src="/public_assets/images/content-pages/deleteacc-3.png" alt=""></img>
                                </div>
                            </div>
                        </div>
                        <div id="section7">
                            <h2>7. Transfer to other countries</h2>
                            <p>
                                When carrying out the activities described in this Policy, {pageInfo.company_shortname} shares your personal data internationally with {pageInfo.company_shortname} group companies, subcontractors and partners. They may process your data in countries whose data protection laws are applied where you live. For example, they may not give you the same rights over your data.<br />
                                Whenever we transfer personal data internationally, we use tools to:
                            </p>
                            <ul>
                                <li>make sure the data transfer complies with applicable law</li>
                                <li>help to give your data the same level of protection</li>
                            </ul>
                            <p>We do this using a variety of protections, as appropriate for each data transfer. For example, we use:</p>
                            <ul>
                                <li><span className="text-primary">Standard Contractual Clauses</span> (or an alternative legal tool) to require the third party to protect your data and to provide you rights and protections</li>
                                <li>technical protections, such as encryption and pseudonymisation</li>
                                <li>policies and processes to challenge disproportionate or unlawful government authority requests</li>
                            </ul>
                            <p>You can exercise your rights under the Standard Contractual Clauses by contacting us or the third party who processes your personal data.</p>
                        </div>
                        <div id="section8">
                            <h2>8. Keeping your personal data safe</h2>
                            <p>
                                We&apos;re committed to protecting our users&apos; personal data. We implement appropriate technical and organisational measures to help protect the security of your personal data. However, be aware that no system is ever completely secure.<br />
                                We have implemented various safeguards including pseudonymisation, encryption, access, and retention policies to guard against unauthorised access and unnecessary retention of personal data in our systems.<br />
                                To protect your user account, we encourage you to:
                            </p>
                            <ul>
                                <li>use a strong password that is unique to your {pageInfo.company_shortname} account</li>
                                <li>never share your password with anyone</li>
                                <li>limit access to your computer and browser</li>
                                <li>log out once you have finished using the {pageInfo.company_shortname} Service on a shared device</li>
                                <li>read more detail on <span className="text-primary">protecting your account</span></li>
                            </ul>
                            <p>
                                If other individuals have access to your {pageInfo.company_shortname} account (for example if you&apos;ve given them permission to use your account on a shared device), then they can access personal data, controls and the {pageInfo.company_shortname} Service available in your account.<br />
                                It&apos;s your responsibility to only provide individuals with permission to use your account where you&apos;re comfortable sharing this personal data with them. Anyone else&apos;s use of your {pageInfo.company_shortname} account may impact your personalised recommendations and be included in your data download.
                            </p>
                        </div>
                        <div id="section9">
                            <h2>9. Children</h2>
                            <p>
                                The {pageInfo.company_shortname} Service has a minimum &quot;Age Limit&quot; in each country. The {pageInfo.company_shortname} Service is not directed to children whose age:
                            </p>
                            <ul>
                                <li>is under the age of 13 years</li>
                                <li>or, makes it illegal to process their personal data</li>
                                <li>or, requires parental consent to process their personal data</li>
                            </ul>
                            <p>
                                We do not knowingly collect personal data from children under the applicable Age Limit. If you&apos;re under the Age Limit, please do not use the {pageInfo.company_shortname} Service, and do not provide any personal data to us.<br />
                                If you&apos;re a parent of a child under the Age Limit and become aware that your child has provided personal data to {pageInfo.company_shortname}, please contact us.<br />
                                If we learn that we&apos;ve collected the personal data of a child under the applicable Age Limit, we&apos;ll take reasonable steps to delete the personal data. This may require us to delete the {pageInfo.company_shortname} account for that child.<br />
                                When using a shared device on the main {pageInfo.company_shortname} Service, you should be cautious about playing or recommending any content to individuals under 18 years old which could be inappropriate for them.
                            </p>
                        </div>
                        <div id="section10">
                            <h2>10. Changes to this Policy</h2>
                            <p>
                                We may occasionally make changes to this Policy.<br />
                                When we make material changes to this Policy, we&apos;ll provide you with prominent notice as appropriate under the circumstances. For example, we may display a prominent notice within the {pageInfo.company_shortname} Service or send you an email or device notification.
                            </p>
                        </div>
                        <div id="section11">
                            <h2>11. How to contact us</h2>
                            <p>For any questions or concerns about this Policy, contact our Data Protection Officer any one of these ways:</p>
                            <ul>
                                <li>Use our privacy contact form</li>
                                <li>Email: {pageInfo.email}</li>
                            </ul>
                            <p>
                                You can also visit our Privacy Center for more clarification of this Policy.<br />
                                &copy; {pageInfo.company_shortname}.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const PaymentPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách thanh toán</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Tiền mặt&nbsp;(COD)</li>
                            <li>Thanh toán chuyển khoản</li>
                        </ol>
                        <table cellSpacing={0} className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Hình thức</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Diễn giải</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>1. Tiền mặt (COD)</strong></p>
                                    </td>
                                    <td>
                                        <p>&nbsp; Khách hàng sẽ thanh toán bằng tiền mặt trực tiếp cho nhân viên giao hàng, hoặc thanh toán cho nhân viên bán hàng mua tại cửa hàng</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>2. Chuyển khoản</strong></p>
                                    </td>
                                    <td>
                                        <p>&nbsp; Sau khi hoàn tất đơn đặt hàng. Khách hàng vui lòng chuyển khoản về địa chỉ:</p>
                                        <p>&nbsp; - Số tài khoản: <a data-z-element-type="phone-number">785555888</a></p>
                                        <p><a data-z-element-type="phone-number">&nbsp; -&nbsp;</a>Tên chủ tài khoản: CONG TY CO PHAN LOCAMART VIET NAM</p>
                                        <p>&nbsp; - Tên ngân hàng: Ngân hàng TMCP Á Châu (ACB)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ShipmentPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách vận chuyển</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p><span style={{ fontSize: '12pt' }}><span><span><strong><span>Thời gian giao hàng</span></strong><strong><span>: </span></strong><span>2-5 ngày làm việc đối với các thành phố,&nbsp;huyện/ xã trên khắp đất nước</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>- Đối với hình thức chuyển khoản, quý khách vui lòng chuyển trước giá trị đơn đặt hàng cho&nbsp;<strong>shop.locamart.vn, </strong>Các tài khoản có sử dụng SMS Banking nên sẽ nhận được thông tin ngay lập tức.</span></span><br />
                            <span><strong><span>shop.locamart.vn </span></strong><span>sẽ ngay lập tức xác nhận thông tin và chuyển hàng cho khách!</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>-Trách nhiệm của bên giao hàng (cung cấp dịch vụ Logistic): chịu trách nhiệm về thời gian giao hàng, tình trạng hàng hóa đảm bảo khi đến tay khách hàng. Trường hợp xảy ra sự cố do lỗi của bên giao hàng hoàn toàn chịu trách nhiệm và chịu bồi thường theo giá trị sản phẩm. <strong>shop.locamart.vn, </strong>sẽ không chịu trách nhiệm do lỗi phát sinh chủ quan của bên giao hàng, trường hợp bất khả kháng </span></span><span><span>chúng tôi</span></span><span><span> sẽ hỗ trợ 50% tổn thất. </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>-Trường hợp phát sinh chậm trễ trong việc giao hàng, <strong>shop.locamart.vn </strong>sẽ liên hệ, thông tin kịp thời cho khách hàng và tạo cơ hội để khách hàng có thể hủy hợp đồng nếu muốn.</span></span></span></span></p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const InspectionPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách kiểm hàng</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Khi khách hàng nhận hàng và kiểm tra kĩ sản phẩm trước khi nhận (quý khách nên quay video trong quá trình mở hộp nhận hàng) và phản hồi ngay cho nhân viên giao hàng, bên chúng tôi sẽ tiến hành khắc phục đổi cho khách hàng.</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Nếu phát sinh những vấn đề ngoài ý muốn về phía Khách hàng, </span><span>shop.locamart.vn </span><span>sẽ hỗ trợ linh động giải quyết trong các trường hợp:</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Địa chỉ không chính xác</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Khách muốn hẹn lịch giao hàng</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Những trường hợp bất khả kháng như thiên tai, tai nạn giao thông… shop.locamart.vn sẽ luôn hỗ trợ và đảm bảo quyền lợi của Quý Khách. </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Mọi thắc mắc và góp ý vui lòng liên hệ</span><span>: </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span style={{ fontFamily: '"Segoe UI Symbol",sans-serif' }}><span style={{ color: '#212338' }}>✆</span></span><span style={{ color: '#212338' }}> Hotline: 0903287666</span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span style={{ fontFamily: '"Segoe UI Symbol",sans-serif' }}><span style={{ color: '#212338' }}>✉</span></span><span style={{ color: '#212338' }}> Email: </span><a href={`mailto:${pageInfo.email}`} style={{ color: '#0563c1', textDecoration: 'underline' }}>{pageInfo.email}</a> </span></span></p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ReturnPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách đổi trả</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Tất cả các sản phẩm của LocaMart được áp dụng đổi trả trong 2h tính từ thời điểm mua hàng.</li>
                            <li>Khi Khách hàng đến cửa hàng đổi trả hoặc yêu cầu nhân sự LocaMart nhận lại hàng cần có đầy đủ:
                                <ul>
                                    <li>Hóa đơn mua hàng</li>
                                    <li>Sản phẩm lỗi hỏng (nếu Khách Hàng gửi hình ảnh thì chỉ chấp nhận hình ảnh sản phẩm lỗi đối với các lỗi có thể quan sát bằng mắt thường như: Hàng mốc, thối, dập…)</li>
                                </ul>
                            </li>
                            <li>Chính sách đổi trả sẽ không áp dụng trong các trường hợp sau:
                                <ul>
                                    <li>Quý khách tự làm ảnh hưởng đến tình trạng bên ngoài như rách bao bì, bong tróc, bể vỡ, rơi rụng…</li>
                                    <li>Quý khách không thực hiện đúng theo cách thức bảo quản dẫn tới việc hư hỏng sản phẩm.</li>
                                </ul>
                            </li>
                            <li>Cách thức thu hồi hàng, hoàn trả tiền khi đổi trả hàng lỗi:
                                <ul>
                                    <li>Trường hợp Khách Hàng đến cửa hàng để đổi trả và hàng hóa đáp ứng điều kiện đổi trả, cửa hàng sẽ tiến hành đổi trả hàng thay thế ngay cho Khách Hàng.</li>
                                    <li>Đối với Khách Hàng khiếu nại qua hình thức khác (như qua tổng đài, fanpage…), chậm nhất 2h kể từ khi tiếp nhận yêu cầu đổi trả hàng của Khách Hàng, LocaMart sẽ liên hệ Khách hàng và tiến hành kiểm tra tình trạng hàng đổi trả.</li>
                                    <li>Trường hợp hàng hóa lỗi đáp ứng điều kiện đổi trả, LocaMart sẽ thu hồi hàng lỗi và đổi hàng hóa đạt yêu cầu thay thế cho Khách Hàng tương ứng với giá trị lượng hàng lỗi mà locaMart nhận được từ Khách Hàng. Nếu không còn hàng hóa để thay thế thì LocaMart sẽ hoàn tiền cho Khách Hàng bằng tiền mặt hoặc chuyển khoản.</li>
                                    <li>Tùy từng trường hợp, LocaMart có thể cử nhân sự hoặc thông qua một đối tác vận chuyển của mình để đến thu hồi hàng. Việc này sẽ được hướng dẫn, thỏa thuận cụ thể khi làm việc với Khách hàng và Khách hàng không phải trả thêm phí cho việc thu hồi hàng lỗi này.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const RightsProtectionPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách bảo đảm quyền lợi</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Khách hàng nhận rau hàng tuần 2kg/lần tại các điểm nhận rau</li>
                            <li>Rau cung cấp cho khách hàng đc tuyển chọn từ Mộc Châu và Đà Lạt, đạt chuẩn VietGAP</li>
                            <li>Gói bảo hiểm trị giá 10 triệu đồng trong trường hợp sức khỏe khách hàng bị ảnh hưởng do sử dụng rau do LocaMart cung cấp</li>
                        </ol>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const Template = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">{pageInfo.company_shortname} - Chính sách bảo mật</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    TermsOfService,
    PrivacyPolicy,
    PaymentPolicy,
    ShipmentPolicy,
    InspectionPolicy,
    ReturnPolicy,
    RightsProtectionPolicy
};