import React from 'react';
import './About.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';

import { ScreenWithNavBarAndFooter, Slider } from 'app/components';
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { motion } from "framer-motion";

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const About = connect(mapStateToProps, actionCreators)(props => {

    const lang = props.pageInfo.display_lang;

    const roadmap_list = [
        {
            title: 'Engaging Features',
            list: `
                <ul>
                    <li>Drop Message: Location-based messaging (text, images, video)</li>
                    <li>3D Message: Location-based 3D messaging</li>
                    <li>LoveLock: 3D love locks</li>
                    <li>LocaGift: Location-based gifting for loved ones</li>
                    <li>LocaMemory: Location-based photo albums</li>
                    <li>DailyDeals: Recording commitments on blockchain</li>
                    <li>DailyTask: Daily tasks to earn LCG</li>
                </ul>
            `
        },
        {
            title: 'Guilding Users to Promote Local Businesses',
            list: `
                <ul>
                    <li>Encouraging check-ins at businesses</li>
                    <li>Promoting 5-stars reviews and comments on Google Maps</li>
                    <li>Sharing positive experiences at businesses</li>
                    <li>Sharing on platforms like Facebook</li>
                    <li>Encouraging purchases and discounts through D-LocaVoucher</li>
                </ul>
            `
        },
        {
            title: 'LocaAI features to support Tourism',
            list: `
                <ul>
                    <li>AI-powered assistant for travel</li>
                    <li>Multilingual translation via AI for chat and calls</li>
                    <li>Connecting with local residents for guided tours</li>
                    <li>Recommending local eateries</li>
                    <li>Planning travel itineraries</li>
                </ul>
            `
        },
        {
            title: 'AR features to encourage Tourism',
            list: `
                <ul>
                    <li>Highlight tourist spots by hosting interactive AR events in each city</li>
                    <li>Offer enticing discovery gift boxes as rewards for exploration</li>
                    <li>Provide areas for digital check-ins and unique personal imprints, like 3D space engravings</li>
                </ul>
            `
        },
        {
            title: 'Connecting Local Businesses features',
            list: `
                <ul>
                    <li>Distributing vouchers for local businesses including dining, accommodation, and souvenirs</li>
                    <li>AR showcasing virtual shops and booths</li>
                    <li>Integration for bookign flights, hotels, and tours</li>
                </ul>
            `
        },
        {
            title: 'Local Economic Platform',
            list: `
                <ul>
                    <li>Expanding Local Industries: Real Estate, Repair Services, Convenience Stores</li>
                    <li>Facilitating trade through virtual real estate buildings</li>
                    <li>Introducing products through AR and VR</li>
                    <li>Augmented reality shopping experiences</li>
                </ul>
            `
        },
    ]

    const team_list = [
        {
            name: 'Berek Nowak',
            position: 'CEO',
            img_url: '/public_assets/images/about-us/team-section/team-1.jpg'
        },
        {
            name: 'Justin J. Manning',
            position: 'COO',
            img_url: '/public_assets/images/about-us/team-section/team-2.jpg'
        },
        {
            name: 'Gladys M. Shofner',
            position: 'Marketing Manager',
            img_url: '/public_assets/images/about-us/team-section/team-3.jpg'
        },
        {
            name: 'Grady A. Mercer',
            position: 'CFO',
            img_url: '/public_assets/images/about-us/team-section/team-4.jpg'
        },
        {
            name: 'Timothy B. Link',
            position: 'CTO',
            img_url: '/public_assets/images/about-us/team-section/team-5.jpg'
        },
        {
            name: 'Alfredo J. Delagarza',
            position: 'Tech Lead',
            img_url: '/public_assets/images/about-us/team-section/team-5.jpg'
        },
    ]
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-section page-header content-page-header fullscreen-page-header">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/about-us/about-us-header-bg.jpg)' }}></motion.div>
                <div className="section-bg upper-mask"></div>
                <div className="section-bg lower-mask"></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="#">{text.top_nav_bar.home[lang]}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{text.top_nav_bar.about_us[lang]}</li>
                            </ol>
                        </nav>
                        <h2 className="sec-title">{text.top_nav_bar.about_us[lang]}</h2>
                    </div>
                </div>
            </header>
            <section className="page-section about-transformation-section">
                <div className="section-bg transformation-section-overlay" style={{ backgroundImage: 'url(/public_assets/images/about-us/transformation-section/transformation-section-overlay.png)' }}></div>
                <div className="section-bg transformation-section-bg" style={{ backgroundImage: 'url(/public_assets/images/about-us/transformation-section/transformation-wrapper-bg.png)' }}></div>
                <div className="container-xl">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        className="transformation-wrapper"
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                            viewport={{ once: true }}
                            className="section-bg coin-top-right" style={{ backgroundImage: 'url(/public_assets/images/about-us/transformation-section/coin-top-right.png)' }}></motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                            viewport={{ once: true }}
                            className="section-bg coins-bottom-left" style={{ backgroundImage: 'url(/public_assets/images/about-us/transformation-section/coins-bottom-left.png)' }}></motion.div>
                        <div className="section-bg locago-app" style={{ backgroundImage: 'url(/public_assets/images/about-us/transformation-section/locago-app.png)' }}></div>
                        <motion.div
                            initial={{ x: 200, rotateZ: 15, opacity: 0 }}
                            whileInView={{ x: 0, rotateZ: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .25, delay: 1 }}
                            viewport={{ once: true }}
                            className="section-bg locago-app-noti desktop" style={{ backgroundImage: 'url(/public_assets/images/about-us/transformation-section/locago-app-notification.png)' }}
                        ></motion.div>
                        <div className="row">
                            <div className="col-md-5">
                                <div className="sec-header">
                                    <motion.h2
                                        initial={{ x: -200, opacity: 0 }}
                                        whileInView={{ x: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                                        viewport={{ once: true }}
                                        className="sec-title"
                                    >Transforming Communities with Innovation</motion.h2>
                                    <motion.p
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                                        viewport={{ once: true }}
                                        className="sec-description"
                                    >
                                        LocaGo represents a pioneering platform within the Web3 GeoEconomy, leveraging Blockchain, AR, AI, and 3D technologies to seamlessly connect users with local businesses. Through the innovative use of LocaNFTs, LocaGo incentivizes user engagement, exploration, and rewards, thus catalyzing economic growth and fostering community development.
                                    </motion.p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </section>
            <div className="page-section about-vision-roadmap-wrapper p-0">
                <div className="section-bg opacity-75" style={{ backgroundImage: 'url(/public_assets/images/about-us/vision-section/vision-roadmap-wrapper-bg-2.jpg)' }}></div>
                <div className="section-bg opacity-25" style={{ backgroundImage: 'url(/public_assets/images/about-us/vision-section/vision-roadmap-wrapper-bg.jpg)' }}></div>
                <section className="page-section about-vision-section">
                    <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/about-us/vision-section/vision-section-bg.png)' }}></div>
                    <div className="container-xl">
                        <div className="row about-vision-row">
                            <div className="col-md-3">
                                <motion.div
                                    initial={{ y: -50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-vision-item active" style={{ backgroundImage: 'url(/public_assets/images/about-us/vision-section/vision-item-active-bg.jpg)' }}
                                >
                                    <img src="/public_assets/images/about-us/vision-section/vision-icon-1.png" alt="" width={56} height={56}></img>
                                    <h3>Our Vision</h3>
                                    <p>A global leader in enhancing real-world experiences and integrating Blockchain into everyday operations.</p>
                                </motion.div>
                            </div>
                            <div className="col-md-3">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5, delay: .25 }}
                                    viewport={{ once: true }}
                                    className="about-vision-item"
                                >
                                    <img src="/public_assets/images/about-us/vision-section/vision-icon-2.png" alt="" width={56} height={56}></img>
                                    <h3>Our Mission</h3>
                                    <p>Deploy AI, AR, VR, Big Data, and Blockchain to empower businesses and strengthen human connections.</p>
                                </motion.div>
                            </div>
                            <div className="col-md-3">
                                <motion.div
                                    initial={{ y: -50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="about-vision-item"
                                >
                                    <img src="/public_assets/images/about-us/vision-section/vision-icon-3.png" alt="" width={56} height={56}></img>
                                    <h3>Goals</h3>
                                    <p>Unite 100M users and 1M businesses across 50 countries by 2027, building vibrant communities worldwide.</p>
                                </motion.div>
                            </div>
                            <div className="col-md-3">
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5, delay: .25 }}
                                    viewport={{ once: true }}
                                    className="about-vision-item"
                                >
                                    <img src="/public_assets/images/about-us/vision-section/vision-icon-4.png" alt="" width={56} height={56}></img>
                                    <h3>Our Philosophy</h3>
                                    <p>Simplify technology for universal accessibility, enabling effortless engagement via blockchain.</p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-section about-roadmap-section">
                    <div className="container-xl">
                        <div className="sec-header text-center">
                            <h2 className="sec-title">LocaGo Roadmap</h2>
                        </div>
                        <div className="sec-content">
                            <Slider
                                className="about-roadmap-slider"
                                list={roadmap_list}
                                renderItem={(item, index) => (
                                    <div className="roadmap-item" key={'roadmapItem_' + index}>
                                        <div className="roadmap-item-index">{index + 1}</div>
                                        <div className="roadmap-item-content">
                                            <h3 className="roadmap-item-title">{item.title}</h3>
                                            <div className="roadmap-item-list" dangerouslySetInnerHTML={{ __html: item.list }}></div>
                                        </div>
                                    </div>
                                )}
                                overrideConfig={{
                                    modules: [Pagination, Navigation, Autoplay],
                                    loop: true,
                                    autoplay: {
                                        delay: 5000
                                    },
                                    slidesPerView: 1,
                                    pagination: true,
                                    centeredSlides: true,
                                    breakpoints: {
                                        768: {
                                            slidesPerView: 2.5
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </section>
            </div>
            {/* <section className="page-section about-team-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/about-us/team-section/team-section-bg.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Our Team</h2>
                        <p className="sec-description">Always ready our team to help you</p>
                    </div>
                    <div className="sec-content">
                        <Slider
                            className="team-slider"
                            list={team_list}
                            renderItem={(item, index) => (
                                <div className="team-item" key={'teamItem_' + index}>
                                    <img src={item.img_url} alt="" className="team-item-img"></img>
                                    <div className="team-item-content">
                                        <h3 className="team-item-name">{item.name}</h3>
                                        <p className="team-item-position">{item.position}</p>
                                    </div>
                                </div>
                            )}
                            overrideConfig={{
                                modules: [Pagination, Navigation, Autoplay],
                                loop: true,
                                autoplay: {
                                    delay: 5000
                                },
                                slidesPerView: 1.5,
                                pagination: true,
                                centeredSlides: true,
                                breakpoints: {
                                    768: {
                                        slidesPerView: 3
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </section> */}
        </ScreenWithNavBarAndFooter>
    )
})

export {
    About
};