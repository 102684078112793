import React, { useState } from 'react';
import './Partner.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { alertActions } from "app/redux/actions";

import { ScreenWithNavBarAndFooter } from 'app/components';
import { motion } from "framer-motion";

import { homepageService } from "services";

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}

const MarketDeveloper = connect(mapStateToProps, actionCreators)(props => {
    const { locale_string } = props.pageInfo;
    const lang = props.pageInfo.display_lang;
    
    const [currentIndex, setCurrentIndex] = useState(0);

    const why_list = [
        {
            title: 'Unlock Earning Potential',
            description: 'Partner with us to make money in our diverse ecosystem and thriving community.',
        },
        {
            title: 'Support LocaGo',
            description: 'Help expand the LocaGo community while earning rewards for your contribution.',
        },
        {
            title: 'Easy Tracking',
            description: 'Monitor your referrals and earnings in real-time via your dashboard on lcm.technology.',
        }
    ]

    function renderWhyLocaNFCController(list, currentIndex) {
        if (!list || list.length === 0) return null;

        let currentProgress = (currentIndex + 1) / list.length * 100;

        let why_controller = [];
        list.forEach((item, index) => {
            why_controller.push(
                <div
                    className={`why-locanfc-controller-item ${index === currentIndex ? 'active' : ''}`}
                    key={`whyLocaNFCItem_${index}`}
                    onClick={() => { setCurrentIndex(index) }}
                >
                    <div className="why-locanfc-item-title">
                        <h5>{item.title}</h5>
                        <img src="/public_assets/images/features/for-businesses/why-section/chevron-down.svg" alt="" width={24} height={24}></img>
                    </div>
                    <p className="why-locanfc-item-description" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
            )
        })

        return (
            <div className="why-locanfc-controller">
                <div className="why-locanfc-progress">
                    <div className="progress-bar current" style={{ right: (100 - currentProgress) + '%' }}></div>
                    <div className="progress-bar total"></div>
                </div>
                { why_controller }
            </div>
        );
    }

    return (
        <ScreenWithNavBarAndFooter className="page-become-a-partner">
            <section className="page-section page-header content-page-header fullscreen-page-header">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg hero" style={{ backgroundImage: 'url(/public_assets/images/partner/hero.jpg)' }}></motion.div>
                <div className="section-bg upper-mask"></div>
                <div className="section-bg lower-mask"></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="#">{text.top_nav_bar.home[lang]}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{text.top_nav_bar.market_developer[lang]}</li>
                            </ol>
                        </nav>
                        <h2 className="sec-title">{text.top_nav_bar.market_developer[lang]}</h2>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-end">
                            <div className="col-md-6 col-xxl-5 ps-md-0">
                                <h3 className="mb-3">LocaGo Market Developer</h3>
                                <p className="mb-0">Are you enthusiastic about shaping the future of a location-based economy? Join our Market Developer program and play a vital role in the growth of LocaGo</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section market-developer-start-section">
                <div className="section-bg bg-blur" style={{ backgroundImage: 'url(/public_assets/images/features/for-businesses/locanfc-section/locanfc-section-blur.png)' }}></div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >How to Get Started?</motion.h2>
                            </div>
                            <div className="sec-content">
                                <ol className="start-list">
                                    <motion.li
                                        initial={{ y: 200, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5 }}
                                        viewport={{ once: true }}
                                    >
                                        <b>Register:</b> Sign up at lcm.technology to become a Market Developer.
                                    </motion.li>
                                    <motion.li
                                        initial={{ y: 200, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5, delay: .25 }}
                                        viewport={{ once: true }}
                                    >
                                        <b>Receive Your Unique Link:</b> Upon registration, you'll get a unique link to share.
                                    </motion.li>
                                    <motion.li
                                        initial={{ y: 200, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                                        viewport={{ once: true }}
                                    >
                                        <b>Spread the Word:</b> Share your link with your network, inviting them to explore and purchase products within the LocaGo ecosystem.
                                    </motion.li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="locago-app" style={{ backgroundImage: 'url(/public_assets/images/features/for-businesses/locanfc-section/locanfc-locago-app.png)' }}>
                    <img src="/public_assets/images/features/for-businesses/locanfc-section/locago-app-1.png" alt="" className="locago-app-1"></img>
                    <img src="/public_assets/images/features/for-businesses/locanfc-section/locago-app-2.png" alt="" className="locago-app-2"></img>
                </div>
            </section>
            <section className="page-section market-developer-benefits-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-businesses/why-section/why-section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="row align-items-end">
                        <div className="col-md-5 why-locanfc-slider-controller-wrapper">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >How It Benefits You?</motion.h2>
                                {/* <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description"
                                >
                                    While registering to become a LocaNFC with LocaGo is free, LocaNFC locations situated at LocaNFTs enjoy the following benefits:
                                </motion.p> */}
                            </div>
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                                viewport={{ once: true }}
                                className="sec-content"
                            >
                                { renderWhyLocaNFCController(why_list, currentIndex) }
                            </motion.div>
                        </div>
                        <div className="col-md-7 why-locanfc-slider-wrapper">
                            <motion.img
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ ease: "easeOut", duration: 2, delay: 1 }}
                                viewport={{ once: true }}
                                src="/public_assets/images/features/for-users/earn-section/locago-app.png" alt="" className="img-fluid w-100 mt-5 mt-md-0"></motion.img>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section market-partner-conditions-section">
                <div className="section-bg wave-1" style={{ backgroundImage: 'url(/public_assets/images/partner/conditions-section/wave-1.png)' }}></div>
                <div className="section-bg wave-2" style={{ backgroundImage: 'url(/public_assets/images/partner/conditions-section/wave-2.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Become a Partner</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Are you a Professional Team Leader? Join us and help LocaGo Community grow more bigger and faster, and receive your deserved benefits!
                        </motion.p>
                    </div>
                    <div className="sec-content">
                        <motion.div
                            initial={{ y: 50, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="partner-condition-item"
                        >
                            <div className="condition-number">1</div>
                            <div className="condition-content">
                                <h3 className="condition-title">CONDITIONS: IN 3 MONTHS</h3>
                                <div className="row">
                                    <div className="col-md-4">
                                        <motion.div
                                            initial={{ y: 50, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5, delay: .25 }}
                                            viewport={{ once: true }}
                                            className="condition-item-1"
                                        >
                                            <img src="/public_assets/images/partner/condition-icon-1.png" alt="" width={56} height={56}></img>
                                            <h4>Experience</h4>
                                            <p>KOLs, experience in Affiliate Development in Thailand, Indonesia, Philippines, India, Russia, Brazil</p>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-4">
                                        <motion.div
                                            initial={{ y: 50, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="condition-item-1"
                                        >
                                            <img src="/public_assets/images/partner/condition-icon-2.png" alt="" width={56} height={56}></img>
                                            <h4>Community</h4>
                                            <p>Minimum Development of <br />20,000 users in 3 months</p>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-4">
                                        <motion.div
                                            initial={{ y: 50, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5, delay: .75 }}
                                            viewport={{ once: true }}
                                            className="condition-item-1"
                                        >
                                            <img src="/public_assets/images/partner/condition-icon-3.png" alt="" width={56} height={56}></img>
                                            <h4>Revenue</h4>
                                            <p>Minimum Sales of <br />$300,000 in 3 months</p>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ y: 50, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="partner-condition-item"
                        >
                            <div className="condition-number">2</div>
                            <div className="condition-content">
                                <h3 className="condition-title">PARTNER BENEFITS</h3>
                                <div className="row">
                                    <div className="col-md-4">
                                        <motion.div
                                            initial={{ y: 50, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5, delay: .25 }}
                                            viewport={{ once: true }}
                                            className="condition-item-2"
                                        >
                                            <h4>10%</h4>
                                            <p>Direct Commission <br />from your sales</p>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-4">
                                        <motion.div
                                            initial={{ y: 50, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                                            viewport={{ once: true }}
                                            className="condition-item-2"
                                        >
                                            <h4>50%</h4>
                                            <p>Commission from the income of your direct referrals</p>
                                        </motion.div>
                                    </div>
                                    <div className="col-md-4">
                                        <motion.div
                                            initial={{ y: 50, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5, delay: .75 }}
                                            viewport={{ once: true }}
                                            className="condition-item-2"
                                        >
                                            <h4>5%</h4>
                                            <p>Additional commission for the entire system you develop, if you meet the conditions above</p>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ y: 50, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="partner-condition-item"
                        >
                            <div className="condition-number">3</div>
                            <div className="condition-content">
                                <h3 className="condition-title">IF CONDITIONS NOT MET</h3>
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5, delay: .25 }}
                                    viewport={{ once: true }}
                                    className="condition-item-2"
                                >
                                    If you can not meet all the conditions within 3 months, you will not receive 5% commission from your developed system, as well as 10% Direct Commission and 50% Commission from the income of your direct referrals.
                                </motion.div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="page-section market-partner-register-section pt-0">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >LocaGo Partner Sign-up</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Please fill in the form below to take the first step to become our Partner. Your registration email must be a new account and not registered on our system. Our representative will contact you as soon as possible
                        </motion.p>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <PartnerRegistrationForm />
                            </div>
                        </div>
                    </div>
                    {/* <motion.div
                        initial={{ y: 200, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                        viewport={{ once: true }}
                        className="sec-content"
                    >
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfgZe7dB46hSvDHgR1-pNu5gV-Z4ssqdwlril7dxX53bnTJug/viewform?embedded=true" width="100%" height="590" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                    </motion.div> */}
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const PartnerRegistrationForm = connect(mapStateToProps, actionCreators)(props => {
    const lang = props.pageInfo.display_lang;

    const { alertSuccess, alertError } = props;

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    function submitForm() {
        // let input = document.getElementById('registerPartnerEmail');
        // let email = input.value;
        let email_patern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

        if (!email || email.length === 0 || email.indexOf('@') === -1 || !email_patern.test(email)) {
            alertError('Please input your email address!');
            return;
        } else {
            setLoading(true);
            homepageService.register_partner(email)
            .then(data => {
                if (data) {
                    alertSuccess('Thank you for your registration. Our representative will contact you as soon as possible!')
                } else {
                    alertError('There was an error sending your registration. Please try again later!')
                }
                setLoading(false);
            })
        }
    }

    return (
        <div className="d-flex flex-column flex-md-row gap-3">
            <input id="registerPartnerEmail" type="email" className="form-control" placeholder="Your email address" onChange={(e) => { setEmail(e.target.value) }}></input>
            <button className="btn btn-primary btn-200" onClick={submitForm} disabled={loading}>{loading ? 'Sending...' : 'Submit'}</button>
        </div>
    )
})

export {
    MarketDeveloper
};