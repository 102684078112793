import React, { useState } from 'react';
import './Features.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';

import { ScreenWithNavBarAndFooter, Slider } from 'app/components';
import { Link } from 'react-router-dom';
import { Autoplay, EffectCube } from "swiper/modules";
import { motion } from "framer-motion";

import { NAVIGATION } from "config";

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const Features = connect(mapStateToProps, actionCreators)(props => {

    const lang = props.pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter className="page-features">
            <header className="page-section page-header content-page-header bg-dark features-page-header">
                <div className="section-bg features-header-blur" style={{ backgroundImage: 'url(/public_assets/images/features/features-header-blur.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="#">{text.top_nav_bar.home[lang]}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{text.top_nav_bar.features[lang]}</li>
                            </ol>
                        </nav>
                        <h2 className="sec-title">{text.top_nav_bar.features[lang]}</h2>
                        <p className="sec-description">LocaGo creates a seamless blend of digital and physical realms, fostering an ecosystem where Digital Citizens and Businesses converge through LocaNFTs.</p>
                    </div>
                </div>
            </header>
            <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "easeOut", duration: 2 }}
                className="page-section features-galaxy-section"
            >
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/features-header-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="sec-content">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="features-galaxy">
                                    <img src="/public_assets/images/features/features-galaxy.png" className="img-fluid w-100 features-galaxy-img"></img>
                                    <img src="/public_assets/images/features/locago-coin.png" className="locago-coin"></img>
                                    <Link to={NAVIGATION.FOR_USERS} className="feature-link for-users">
                                        <span className="feature-link-text">Features for Users</span>
                                        <span className="feature-link-icon">
                                            <img src="/public_assets/images/features/icon-for-users.png"></img>
                                        </span>
                                    </Link>
                                    <Link to={NAVIGATION.FOR_BUSINESSES} className="feature-link for-businesses">
                                        <span className="feature-link-icon">
                                            <img src="/public_assets/images/features/icon-for-businesses.png"></img>
                                        </span>
                                        <span className="feature-link-text">For LocaNFC Businesses</span>
                                    </Link>
                                    <Link to={NAVIGATION.FOR_OWNERS} className="feature-link for-owners">
                                        <span className="feature-link-icon">
                                            <img src="/public_assets/images/features/icon-for-owners.png"></img>
                                        </span>
                                        <span className="feature-link-text">For LocaNFT Owners</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.section>
        </ScreenWithNavBarAndFooter>
    )
})

const ForUsers = connect(mapStateToProps, actionCreators)(props => {

    const lang = props.pageInfo.display_lang;

    const [headerSlide, setHeaderSlide] = useState(null);
    const [headerThumbs, setHeaderThumbs] = useState(null);

    const for_users_slides = [
        {
            title: '3D Message',
            icon_url: '/public_assets/images/features/for-users/page-header/header-slide-1/slide-icon',
            img_url: '/public_assets/images/features/for-users/page-header/header-slide-4/locago-app.png'
        },
        {
            title: 'Drop Message',
            icon_url: '/public_assets/images/features/for-users/page-header/header-slide-2/slide-icon',
            img_url: '/public_assets/images/features/for-users/page-header/header-slide-4/locago-app.png'
        },
        {
            title: 'LocaMemory',
            icon_url: '/public_assets/images/features/for-users/page-header/header-slide-3/slide-icon',
            img_url: '/public_assets/images/features/for-users/page-header/header-slide-4/locago-app.png'
        },
        {
            title: 'LoveLock',
            icon_url: '/public_assets/images/features/for-users/page-header/header-slide-4/slide-icon',
            img_url: '/public_assets/images/features/for-users/page-header/header-slide-4/locago-app.png'
        },
        {
            title: 'Daily Deals',
            icon_url: '/public_assets/images/features/for-users/page-header/header-slide-5/slide-icon',
            img_url: '/public_assets/images/features/for-users/page-header/header-slide-4/locago-app.png'
        },
        {
            title: 'LocaAI',
            icon_url: '/public_assets/images/features/for-users/page-header/header-slide-6/slide-icon',
            img_url: '/public_assets/images/features/for-users/page-header/header-slide-4/locago-app.png'
        },
    ]
    
    return (
        <ScreenWithNavBarAndFooter className="page-features">
            <header className="page-section page-header content-page-header fullscreen-page-header features-for-users-page-header">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg features-for-users-header-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/page-header/for-users-header-bg.jpg)' }}
                ></motion.div>
                <div className="section-bg upper-mask"></div>
                <div className="section-bg lower-mask"></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="#">{text.top_nav_bar.home[lang]}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{text.top_nav_bar.features[lang]}</li>
                            </ol>
                        </nav>
                        <h2 className="sec-title">{text.top_nav_bar.features.for_users[lang]}</h2>
                    </div>
                </div>
                <div className="features-for-users-header-slider-wrapper">
                    <Slider
                        className="features-for-users-header-slider"
                        list={for_users_slides}
                        renderItem={(item, index) => (
                            <div className="for-users-header-slide-item" key={'forUsersFeatureItem_' + index} style={{ backgroundImage: 'url(' + item.img_url + ')' }}></div>
                        )}
                        overrideConfig={{
                            onSwiper: setHeaderSlide,
                            modules: [Autoplay, EffectCube],
                            slidesPerView: 1,
                            centeredSlides: true,
                            autoplay: {
                                delay: 5000
                            },
                            // effect: 'cube',
                            cubeEffect: {
                                shadow: true,
                                slideShadows: true,
                                shadowOffset: 20,
                                shadowScale: 0.94,
                            }
                        }}
                        indexChangeCallback={(index) => {
                            if (headerThumbs) {
                                headerThumbs.slideTo(index);
                            }
                        }}
                    />
                </div>
                <div className="features-for-users-header-thumbs-wrapper">
                    <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/page-header/header-slide-thumb-ellipse.png)' }}></div>
                    <Slider
                        className="features-for-users-header-thumbs"
                        list={for_users_slides}
                        renderItem={(item, index) => (
                            <div className="for-users-header-thumb-item" key={'forUsersFeatureThumb_' + index}>
                                <div className="thumb-icon">
                                    <img src={item.icon_url + '.png'} alt="" width={40} height={40} className="thumb-icon-inactive"></img>
                                    <img src={item.icon_url + '-active.png'} alt="" width={40} height={40} className="thumb-icon-active"></img>
                                </div>
                                <p className="m-0">{item.title}</p>
                            </div>
                        )}
                        overrideConfig={{
                            onSwiper: setHeaderThumbs,
                            slidesPerView: 3,
                            centeredSlides: true,
                            slideToClickedSlide: true,
                            breakpoints: {
                                768: {
                                    slidesPerView: 6
                                }
                            }
                        }}
                        indexChangeCallback={(index) => {
                            if (headerSlide) {
                                headerSlide.slideTo(index);
                            }
                        }}
                    />
                </div>
            </header>
            <section className="page-section features-for-users-why-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/why-section/why-section-bg.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Why Participate in LocaGo?</motion.h2>
                    </div>
                    <div className="sec-content">
                        <motion.div
                            initial={{ y: 200, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                            viewport={{ once: true }}
                            className="why-wrapper"
                        >
                            <div className="row">
                                <div className="col-6 col-md-4 mt-md-5 order-first">
                                    <h3>Earn Rewards</h3>
                                    <p>Engage in platform activities to earn LCB points and LCG tokens for free.</p>
                                </div>
                                <div className="col-6 col-md-4 my-4 my-md-0 order-4 order-md-1">
                                    <h3>Fair Compensation</h3>
                                    <p>LocaGo ensures fair and transparent compensation for every contribution.</p>
                                </div>
                                <div className="col-6 col-md-4 mt-md-5 order-1 order-md-2">
                                    <h3>Shape the Future</h3>
                                    <p>Your involvement directly impacts the success of the platform.</p>
                                </div>
                                <div className="col-6 col-md-4 align-self-end mt-4 mt-md-0 mb-md-4 order-2 order-md-3">
                                    <h3>Community Engagement</h3>
                                    <p>Connect with a vibrant community of users and partners.</p>
                                </div>
                                <div className=" col-md-4 px-md-0 order-last order-md-4">
                                    <img src="/public_assets/images/features/for-users/why-section/locago-app.png" alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="col-6 col-md-4 align-self-end mb-md-4 order-3 order-md-5">
                                    <h3>Membership Benefits</h3>
                                    <p>Upgrade to unlock exclusive perks tailored to your preferences.</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="page-section features-for-users-earn-section">
                <div className="section-bg earn-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/earn-section/earn-section-bg.jpg)' }}></div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    viewport={{ once: true }}
                    className="section-bg locago-app desktop" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/earn-section/locago-app.png)' }}></motion.div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >How to Earn Rewards?</motion.h2>
                            </div>
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-content"
                            >
                                <p>Engage in a variety of platform-building activities to earn LCB points and LCG tokens completely free. These activities include:</p>
                                <ul className="ms-3 mb-4 mb-md-0">
                                    <li>Purchasing items</li>
                                    <li>Using D-LocaVoucher and business partner vouchers</li>
                                    <li>Successfully referring businesses</li>
                                    <li>Rating or commenting on business partners</li>
                                    <li>Successfully referring others to the LocaGo app</li>
                                    <li>Purchasing products or services within the ecosystem</li>
                                    <li>Successfully checking in at various LocaNFTs</li>
                                </ul>
                                <img src="/public_assets/images/features/for-users/earn-section/locago-app.png" alt="" className="img-fluid w-100 mobile"></img>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section features-for-users-weight-section">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    viewport={{ once: true }}
                    className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/weight-section/weight-section-bg.jpg)' }}></motion.div>
                <div className="container-xl">
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 2, delay: 1 }}
                        viewport={{ once: true }}
                        className="section-bg locago-coins desktop" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/weight-section/coins.png)' }}></motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 2, delay: 1 }}
                        viewport={{ once: true }}
                        className="section-bg locago-coins mobile" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/weight-section/coins-mobile.png)' }}></motion.div>
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Calculation of Weight</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            LocaGo measures contributions in Weight (EWE), with each action counting as 1 EWE. Additional EWEs can be purchased for $1 each. Weekly weights determine your rewards
                        </motion.p>
                    </div>
                </div>
            </section>
            {/* <section className="page-section features-for-users-packages-section">
                <div className="section-bg blur-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/package-section/package-section-blur.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Membership Packages</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Upgrade your LocaGo experience with Membership packages for added benefits. Choose from a variety of tailored packages to enhance your platform use.
                        </motion.p>
                    </div>
                    <motion.div
                        initial={{ y: 200, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-content"
                    >
                        <MembershipPackage />
                    </motion.div>
                </div>
            </section> */}
            <section className="page-section features-for-users-promo-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/promo-section/promo-section-bg.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-content">
                        <motion.div
                            initial={{ y: -200, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="promo-img desktop" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/promo-section/promo-section-img.png)' }}></motion.div>
                        <div className="promo-wrapper">
                            <div className="promo-body">
                                <div className="row align-items-center">
                                    <div className="col-md-5">
                                        <motion.h3
                                            initial={{ x: -200, opacity: 0 }}
                                            whileInView={{ x: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                        >Ready to Unlock Premium Benefits?</motion.h3>
                                        <motion.p
                                            initial={{ opacity: 0 }}
                                            whileInView={{ opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            className="my-5"
                                        >
                                            Click below to explore Membership packages and elevate your LocaGo experience!
                                        </motion.p>
                                        <motion.a
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            href="http://download.locago.tech" target="_blank" className="btn btn-primary btn-200 px-5"
                                        >Learn more</motion.a>
                                    </div>
                                    <div className="col-md-6 mobile mt-5 mt-md-0">
                                        <img src="/public_assets/images/features/for-users/promo-section/promo-section-img.png" alt="" className="img-fluid"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-footer">
                                <p>Web3.0 GeoEconomy</p>
                                <img src="/public_assets/images/tokenomics/discover-section/locago-logo.png" alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ForBusinesses = connect(mapStateToProps, actionCreators)(props => {
    const lang = props.pageInfo.display_lang;

    const [currentIndex, setCurrentIndex] = useState(0);

    const why_list = [
        {
            title: 'Introducing Gift Boxes to Attract Customers for LocaNFCs',
            description: 'Gift boxes are designed to attract daily customers to LocaNFTs or LocaNFC business locations. These boxes may contain vouchers, samples, or products with prices contributed by various businesses.',
        },
        {
            title: 'Allocation Method',
            description: `
                <ul>
                    <li>Daily allocation: Starting from 06/2024, 2,000,000 LCG tokens allocated daily, decreasing by 5% monthly.</li>
                    <li>Gift box distribution: If no LocaNFC businesses participate, 1,000,000 gift boxes evenly distributed to 1,000,000 LocaNFTs.</li>
                    <li>User allocation: Each gift box divided into 100 portions based on user weight, limited to 1 gift box per day per user.</li>
                </ul>
            `,
        },
        {
            title: 'Gift Box Allocation and LocaNFC Owner Benefits',
            description: 'LocaNFC business owners can purchase packages ranging from $10 to $2000 per purchase.',
        },
        {
            title: 'Allocation breakdown:',
            description: `
                <ul>
                    <li>40% for customers who successfully purchase products or services.</li>
                    <li>40% for the store owner (based on the number of customers received).</li>
                    <li>10% for the NFT owner if the business is within that NFT.</li>
                    <li>10% for the referrer of the business who purchased the gift box.</li>
                </ul>
            `,
        },
        {
            title: 'Rewards for LocaNFC owners:',
            description: `
                <ul>
                    <li>Receive 1 LCB for every successful customer action.</li>
                    <li>Must accumulate 5 LCBs for daily LCG amounts; otherwise, they receive 50% of the LCG amount.</li>
                    <li>LocaNFT investors or businesses can offer fee-based services to attract customers and sell products within their LocaNFTs.</li>
                </ul>
            `,
        },
    ]

    function renderWhyLocaNFCController(list, currentIndex) {
        if (!list || list.length === 0) return null;

        let currentProgress = (currentIndex + 1) / list.length * 100;

        let why_controller = [];
        list.forEach((item, index) => {
            why_controller.push(
                <div
                    className={`why-locanfc-controller-item ${index === currentIndex ? 'active' : ''}`}
                    key={`whyLocaNFCItem_${index}`}
                    onClick={() => { setCurrentIndex(index) }}
                >
                    <div className="why-locanfc-item-title">
                        <h5>{item.title}</h5>
                        <img src="/public_assets/images/features/for-businesses/why-section/chevron-down.svg" alt="" width={24} height={24}></img>
                    </div>
                    <p className="why-locanfc-item-description" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
            )
        })

        return (
            <div className="why-locanfc-controller">
                <div className="why-locanfc-progress">
                    <div className="progress-bar current" style={{ right: (100 - currentProgress) + '%' }}></div>
                    <div className="progress-bar total"></div>
                </div>
                { why_controller }
            </div>
        );
    }

    return (
        <ScreenWithNavBarAndFooter className="page-features">
            <header className="page-section page-header content-page-header fullscreen-page-header bg-dark features-for-businesses-page-header">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg features-for-businesses-header-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-businesses/page-header/for-businesses-header-bg.jpg)' }}
                ></motion.div>
                <div className="section-bg upper-mask"></div>
                <div className="section-bg lower-mask"></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="#">{text.top_nav_bar.home[lang]}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{text.top_nav_bar.features[lang]}</li>
                            </ol>
                        </nav>
                        <h2 className="sec-title">Features For Business</h2>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start">
                                <h3>Revolutionize Your Marketing Strategy with LocaGo</h3>
                                <p>Transform Your Business into a Thriving Hub of Activity and Engagement with LocaGo's Next-Generation Marketing Tools.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="page-section features-for-businesses-locanfc-section">
                <div className="section-bg bg-blur" style={{ backgroundImage: 'url(/public_assets/images/features/for-businesses/locanfc-section/locanfc-section-blur.png)' }}></div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >What is LocaNFC?</motion.h2>
                            </div>
                            <div className="sec-content">
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="mb-0"
                                >
                                    LocaNFC represents locations that partner with LocaGo to revolutionize their marketing strategies. By leveraging innovative features, LocaNFC businesses gain alternatives to costly advertising campaigns, enhancing customer attraction and retention.
                                </motion.p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="locago-app" style={{ backgroundImage: 'url(/public_assets/images/features/for-businesses/locanfc-section/locanfc-locago-app.png)' }}>
                    <img src="/public_assets/images/features/for-businesses/locanfc-section/locago-app-1.png" alt="" className="locago-app-1"></img>
                    <img src="/public_assets/images/features/for-businesses/locanfc-section/locago-app-2.png" alt="" className="locago-app-2"></img>
                </div>
            </section>
            <section className="page-section features-for-businesses-why-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-businesses/why-section/why-section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="row align-items-end">
                        <div className="col-md-5 why-locanfc-slider-controller-wrapper">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Why LocaNFC?</motion.h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description"
                                >
                                    While registering to become a LocaNFC with LocaGo is free, LocaNFC locations situated at LocaNFTs enjoy the following benefits:
                                </motion.p>
                            </div>
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                                viewport={{ once: true }}
                                className="sec-content"
                            >
                                { renderWhyLocaNFCController(why_list, currentIndex) }
                            </motion.div>
                        </div>
                        <div className="col-md-7 why-locanfc-slider-wrapper">
                            <motion.img
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ ease: "easeOut", duration: 2, delay: 1 }}
                                viewport={{ once: true }}
                                src="/public_assets/images/features/for-users/earn-section/locago-app.png" alt="" className="img-fluid w-100 mt-5 mt-md-0"></motion.img>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section features-for-users-promo-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/promo-section/promo-section-bg.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-content">
                        <motion.div
                            initial={{ y: -200, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="promo-img desktop" style={{ backgroundImage: 'url(/public_assets/images/home/rewards-section/locago-promo.png)' }}></motion.div>
                        <div className="promo-wrapper">
                            <div className="promo-body">
                                <div className="row align-items-center">
                                    <div className="col-md-5">
                                        <motion.h3
                                            initial={{ x: -200, opacity: 0 }}
                                            whileInView={{ x: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                        >Unlock the Potential of LocaNFC Ownership and Supercharge Your Business with Gift Boxes!</motion.h3>
                                        <motion.a
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            href="http://lcm.technology" target="_blank" className="btn btn-primary btn-200 px-5 mt-5"
                                        >Register Now</motion.a>
                                    </div>
                                    <div className="col-md-6 mobile mt-5 mt-md-0">
                                        <img src="/public_assets/images/home/rewards-section/locago-promo.png" alt="" className="img-fluid"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-footer">
                                <p>Web3.0 GeoEconomy</p>
                                <img src="/public_assets/images/tokenomics/discover-section/locago-logo.png" alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ForOwners = connect(mapStateToProps, actionCreators)(props => {
    const lang = props.pageInfo.display_lang;

    const [currentIndex, setCurrentIndex] = useState(0);

    const why_list = [
        {
            title: 'Passive Rewards',
            description: `
                <ul>
                    <li>Receive daily LCG tokens over 10 years, conditional on 5 LCBs or NFC partner activities.</li>
                    <li>Exclusive brand advertising rights at owned LocaNFTs.</li>
                    <li>10% of LCG tokens from LocaNFC activities.</li>
                    <li>20% of user-paid LCG tokens for LocaNFT activities.</li>
                    <li>55% advertising revenue, increasing to 80% if self-sourced.</li>
                    <li>20% of LCB points for user activities.</li>
                    <li>15% off rewards and D-LocaVoucher gifts.</li>
                </ul>
            `,
        },
        {
            title: 'Autonomy for Marketing Campaigns',
            description: `
                <ul>
                    <li>Daily LocaNFC gift boxes and surprise gifts.</li>
                    <li>Issuing vouchers during off-peak hours.</li>
                    <li>Self-gifting and voucher-dropping.</li>
                    <li>Google Maps rating/comments.</li>
                    <li>Increasing likes and shares on Facebook.</li>
                    <li>Displaying business info on the LocaGo app and LocaAI.</li>
                    <li>Booking for customers.</li>
                </ul>
            `,
        }
    ]

    function renderWhyLocaNFTController(list, currentIndex) {
        if (!list || list.length === 0) return null;

        let currentProgress = (currentIndex + 1) / list.length * 100;

        let why_controller = [];
        list.forEach((item, index) => {
            why_controller.push(
                <div
                    className={`why-locanfc-controller-item ${index === currentIndex ? 'active' : ''}`}
                    key={`whyLocaNFCItem_${index}`}
                    onClick={() => { setCurrentIndex(index) }}
                >
                    <div className="why-locanfc-item-title">
                        <h5>{item.title}</h5>
                        <img src="/public_assets/images/features/for-businesses/why-section/chevron-down.svg" alt="" width={24} height={24}></img>
                    </div>
                    <p className="why-locanfc-item-description" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
            )
        })

        return (
            <div className="why-locanfc-controller">
                <div className="why-locanfc-progress">
                    <div className="progress-bar current" style={{ right: (100 - currentProgress) + '%' }}></div>
                    <div className="progress-bar total"></div>
                </div>
                { why_controller }
            </div>
        );
    }

    return (
        <ScreenWithNavBarAndFooter className="page-features">
            <header className="page-section page-header content-page-header fullscreen-page-header bg-dark features-for-owners-page-header">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg features-for-owners-header-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-owners/page-header/for-owners-header-bg.jpg)' }}
                ></motion.div>
                <motion.div
                    initial={{ opacity: 0, scale: 4 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ ease: "easeOut", duration: .5 }}
                    className="section-bg features-for-owners-wave desktop" style={{ backgroundImage: 'url(/public_assets/images/features/for-owners/page-header/for-owners-header-wave.png)' }}
                ></motion.div>
                <div className="section-bg upper-mask"></div>
                <div className="section-bg lower-mask"></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="#">{text.top_nav_bar.home[lang]}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{text.top_nav_bar.features[lang]}</li>
                            </ol>
                        </nav>
                        <h2 className="sec-title">Features For LocaNFT Owners</h2>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-6 col-xxl-5 text-center text-md-start">
                                <h3>Unlock the Potential of LocaNFTs</h3>
                                <p>Leverage Real-World Sites to Engage Users and Boost Revenue with LocaGo's Innovative Ecosystem</p>
                                <div className="features-for-owners-wave mobile mt-5">
                                    <img src="/public_assets/images/features/for-owners/page-header/for-owners-header-wave.png" alt="" className="img-fluid w-100"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section id="locanft" className="page-section what-is-locanft-section">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Why LocaNFT?</motion.h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description"
                                >
                                    LocaNFTs are GPS-identified physical sites such as squares, parks, and restaurants. Through the LocaGo app, users can engage with these locations within a 50m radius, participating in various activities.
                                </motion.p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="locanft-maps-wrapper">
                    <div className="container-xl">
                        <motion.div
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: 2 }}
                            viewport={{ once: true }}
                            className="locanft-maps-animation"
                        >
                            <div className="locanft-maps-bg">
                                <img src="/public_assets/images/services/for-owners/maps-bg.jpg" alt="" className="img-fluid w-100"></img>
                            </div>
                            <div className="locanft-maps-elements">
                                <img src="/public_assets/images/services/for-owners/nav-arrow.svg" alt="" className="nav-arrow"></img>
                                <img src="/public_assets/images/services/for-owners/nft-pin.png" alt="" className="nft-pin pin-1"></img>
                                <img src="/public_assets/images/services/for-owners/nft-pin.png" alt="" className="nft-pin pin-2"></img>
                                <img src="/public_assets/images/services/for-owners/nft-pin.png" alt="" className="nft-pin pin-3"></img>
                                <img src="/public_assets/images/services/for-owners/nft-pin.png" alt="" className="nft-pin pin-4"></img>
                                <img src="/public_assets/images/services/for-owners/nav-routes.png" alt="" className="img-fluid w-100"></img>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="page-section features-for-owners-why-section">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    viewport={{ once: true }}
                    className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-owners/why-section/why-section-bg.jpg)' }}></motion.div>
                <div className="container-xl">
                    <div className="row align-items-end">
                        <div className="col-md-5 why-locanft-slider-controller-wrapper">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Why LocaNFT?</motion.h2>
                            </div>
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="sec-content"
                            >
                                { renderWhyLocaNFTController(why_list, currentIndex) }
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section features-for-users-promo-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/promo-section/promo-section-bg.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-content">
                        <motion.div
                            initial={{ y: -200, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="promo-img desktop" style={{ backgroundImage: 'url(/public_assets/images/features/for-owners/promo-section/locago-app.png)' }}></motion.div>
                        <div className="promo-wrapper">
                            <div className="promo-body">
                                <div className="row align-items-center">
                                    <div className="col-md-5">
                                        <motion.h3
                                            initial={{ x: -200, opacity: 0 }}
                                            whileInView={{ x: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                        >Ready to Maximize Your LocaNFT's Potential?</motion.h3>
                                        <motion.p
                                            initial={{ opacity: 0 }}
                                            whileInView={{ opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            className="my-5"
                                        >
                                            Click below to explore the opportunities and unlock the full potential of your LocaNFT!
                                        </motion.p>
                                        <motion.a
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            href="http://lcm.technology" target="_blank" className="btn btn-primary btn-200 px-5"
                                        >Register Now</motion.a>
                                    </div>
                                    <div className="col-md-6 mobile mt-5 mt-md-0">
                                        <img src="/public_assets/images/features/for-owners/promo-section/locago-app.png" alt="" className="img-fluid"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-footer">
                                <p>Web3.0 GeoEconomy</p>
                                <img src="/public_assets/images/tokenomics/discover-section/locago-logo.png" alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const MembershipPackage = connect(mapStateToProps, actionCreators)(props => {
    const { lang, locale_string } = props.pageInfo;

	const package_list = [
		{
			id: 'basic',
			name: 'Basic',
			price: 0,
			billing_period: 'Monthly',
            ewe_weight: 1,
			yeild: 'LCG is distributed once per day and requires 2 LCBs per day',
			gift_pickup: 2,
			voucher: 2,
			message_drop: 2,
			gift_delivery: 2,
			lovelock: 0
		},
		{
			id: 'standard',
			name: 'Standard',
			price: 20,
			billing_period: 'Monthly',
            ewe_weight: 5,
			yeild: 'LCG is distributed once per day and requires 2 LCBs per day',
			gift_pickup: 5,
			voucher: 5,
			message_drop: 5,
			gift_delivery: 5,
			lovelock: 0
		},
		{
			id: 'bronze',
			name: 'Bronze',
			price: 200,
			billing_period: 'Monthly',
            ewe_weight: 50,
			yeild: 'LCG is distributed once per day and requires 2 LCBs per day',
			gift_pickup: 10,
			voucher: 10,
			message_drop: 10,
			gift_delivery: 10,
			lovelock: 1
		},
		{
			id: 'silver',
			name: 'Silver',
			price: 500,
			billing_period: 'Monthly',
            ewe_weight: 125,
			yeild: 'LCG is distributed once per day and requires 2 LCBs per day',
			gift_pickup: 20,
			voucher: 20,
			message_drop: 20,
			gift_delivery: 20,
			lovelock: 2
		},
		{
			id: 'gold',
			name: 'Gold',
			price: 1000,
			billing_period: 'Monthly',
            ewe_weight: 250,
			yeild: 'LCG is distributed once per day and requires 2 LCBs per day',
			gift_pickup: 50,
			voucher: 50,
			message_drop: 50,
			gift_delivery: 50,
			lovelock: 5
		},
		{
			id: 'platinum',
			name: 'Platinum',
			price: 2000,
			billing_period: 'Monthly',
            ewe_weight: 500,
			yeild: 'LCG is distributed once per day and requires 2 LCBs per day',
			gift_pickup: 100,
			voucher: 100,
			message_drop: 100,
			gift_delivery: 100,
			lovelock: 10
		},
	]

	return (
		<Slider
			className="membership-package-slider"
			list={package_list}
			renderItem={(item, index) => (
				<div className="membership-package" key={`membershipPackage_${index}`}>
                    <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/features/for-users/package-section/membership-package-bg-active.png)' }}></div>
					<div className="membership-package-header">
                        <h3 className="membership-package-name">{item.name}</h3>
                        <div className="membership-package-price">
                            <h3>{item.price > 0 ? `$${item.price.toLocaleString(locale_string)}` : 'FREE'}</h3>
                            <p>{item.duration > 0 ? `/ ${item.duration} months` : ''}</p>
                        </div>
                        <p>Billed {item.billing_period}</p>
					</div>
                    <hr className="my-4" />
					<div className="membership-package-body">
                        <ul>
                            <li>{item.gift_pickup} {item.gift_pickup > 1 ? 'times' : 'time'}/day</li>
                            <li>{item.voucher} {item.voucher > 1 ? 'vouchers' : 'voucher'}/day</li>
                            <li>{item.message_drop} Message Drops</li>
                            <li>{item.gift_delivery}  {item.gift_delivery > 1 ? 'times' : 'time'} LocaGift delivery</li>
                            <li>{item.lovelock} {item.lovelock > 1 ? 'times' : 'time'} LoveLock creation</li>
                            <li>{item.yeild}</li>
                            <li>x{item.ewe_weight} EWE Weight</li>
                        </ul>
					</div>
                    <div className="membership-package-footer text-center">
                        <a href="#" target="_blank" className="btn btn-primary btn-200">Choose Plan</a>
                    </div>
				</div>
			)}
			overrideConfig={{
				autoHeight: true,
				spaceBetween: 14,
				slidesPerView: 1,
                centeredSlides: true,
                slideToClickedSlide: true,
				breakpoints: {
					768: {
						slidesPerView: 3
					}
				}
			}}
		/>
	)
})


export {
    Features,
    ForUsers,
    ForBusinesses,
    ForOwners
};