import React from 'react';
import './Tokenomics.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';

import { ScreenWithNavBarAndFooter } from 'app/components';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";

import { NAVIGATION } from "config";

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const Tokenomics = connect(mapStateToProps, actionCreators)(props => {

    const lang = props.pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter className="page-tokenomics">
            <header className="page-section page-header content-page-header fullscreen-page-header tokenomics-page-header">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg desktop" style={{ backgroundImage: 'url(/public_assets/images/tokenomics/tokenomics-header-bg.jpg)' }}></motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="section-bg mobile" style={{ backgroundImage: 'url(/public_assets/images/tokenomics/tokenomics-header-bg-mobile.jpg)' }}></motion.div>
                <div className="section-bg upper-mask"></div>
                <div className="section-bg lower-mask"></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="#">{text.top_nav_bar.home[lang]}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{text.top_nav_bar.tokenomics[lang]}</li>
                            </ol>
                        </nav>
                        <h2 className="sec-title">{text.top_nav_bar.tokenomics[lang]}</h2>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-6 col-xxl-5 pe-md-0">
                                <h3 className="mb-3">LocaGo Economy <br className="desktop" />Regulated by LCB Rewards <br className="desktop" />and LCG Governance Token</h3>
                                <p className="mb-0">LocaGo's Tokenomics fosters a balanced and sustainable economy, driving engagement from users and businesses to enhance the value of LCG and LCB.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="page-section tokenomics-info-section">
                <div className="section-bg tokenomics-section-overlay" style={{ backgroundImage: 'url(/public_assets/images/about-us/transformation-section/transformation-section-overlay.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-content">
                        <motion.div
                            initial={{ y: 200, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="token-info-row"
                        >
                            <div className="row">
                                <div className="col-md-4">
                                    <h6>Total Supply</h6>
                                    <p className="mb-4 mb-md-0">21 Billion LCG</p>
                                </div>
                                <div className="col-md-3">
                                    <h6>Token Name</h6>
                                    <p className="mb-4 mb-md-0">LCG</p>
                                </div>
                                <div className="col-md-5">
                                    <h6>Blockchain</h6>
                                    <p className="mb-0">Binance Smart Chain</p>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ y: 200, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5, delay: .5 }}
                            viewport={{ once: true }}
                            className="tokenomics-graph"
                        >
                            <div className="sec-header text-center">
                                <h2 className="sec-title">Token Allocation</h2>
                            </div>
                            <img src="/public_assets/images/tokenomics/locago-tokenomics.png" alt="" className="img-fluid w-100 desktop"></img>
                            <img src="/public_assets/images/tokenomics/locago-tokenomics-mobile.png" alt="" className="img-fluid w-100 mobile"></img>
                        </motion.div>
                    </div>
                </div>
            </section>
            <section className="page-section tokenomics-discover-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/tokenomics/discover-section/discover-section-bg.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-content">
                        <motion.div
                            initial={{ y: -200, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="discover-img desktop">
                            <img src="/public_assets/images/tokenomics/discover-section/locago-coins.png" alt="" className="img-fluid w-100"></img>
                        </motion.div>
                        <div className="discover-wrapper">
                            <div className="discover-body">
                                <div className="row align-items-center">
                                    <div className="col-md-5">
                                        <motion.h3
                                            initial={{ x: -200, opacity: 0 }}
                                            whileInView={{ x: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                        >Discover more about token allocation details for various activities</motion.h3>
                                        <motion.a
                                            initial={{ y: 200, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{ ease: "easeOut", duration: .5 }}
                                            viewport={{ once: true }}
                                            href="https://locago.gitbook.io/locago-whitepaper-official" target="_blank" className="btn btn-primary btn-200 px-5 mt-5"
                                        >Learn more</motion.a>
                                    </div>
                                    <div className="col-md-6 mobile mt-5 mt-md-0">
                                        <img src="/public_assets/images/tokenomics/discover-section/locago-coins.png" alt="" className="img-fluid"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="discover-footer">
                                <p>Web3.0 GeoEconomy</p>
                                <img src="/public_assets/images/tokenomics/discover-section/locago-logo.png" alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    Tokenomics
};